// Account store
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import Loading from 'quasar/src/plugins/loading/Loading.js';
import { useCentralStore } from './central';
import { useViewStore } from './view';
import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';
import cloneDeep from 'lodash.clonedeep';
import { AxiosDataParser } from 'src/plugins/axios/axiosParser';
import { tteCalculator } from 'src/features/account/methods/tteCalculator';
import { StandardAlertsPlugin } from 'src/plugins/alert/alertPlugin';
export const useAccountStore = defineStore('account', () => {
    const centralStore = useCentralStore();
    const viewStore = useViewStore();
    const { errorAlert, successAlert } = StandardAlertsPlugin();
    async function firebaseLoginWithToken(token) {
        try {
            const { signInWithCustomToken } = await import(
            /* webpackChunkName: "firebaseAuth" */
            'firebase/auth');
            const firebaseModule = await import('src/_helpers/firebaseMethods');
            const { auth } = await firebaseModule.default();
            return await signInWithCustomToken(auth, token);
        }
        catch (error) {
            const firebaseError = error;
            errorAlert(`Code: ${firebaseError.code || ''}. Message: ${firebaseError.message || ''}`);
            return false;
        }
    }
    const user = ref(null);
    const userMisc = ref(null);
    /*****
     * Firebase
     * ****/
    const firebaseToken = ref(null);
    const firebaseLoggedIn = ref(false);
    /**
     * @param bool whether user is logged in to firebase
     */
    function toggleFirebaseLoggedIn(bool) {
        firebaseLoggedIn.value = bool;
    }
    /**
     * Get token from backend then login to firebase
     * @returns firebase token
     */
    async function firebaseLogin() {
        try {
            const firebaseAxios = new AxiosDataParser({
                url: 'firebase_login',
                urlType: 'common',
                type: 'list'
            }, {
                timeToExpire: tteCalculator(user.value),
                customAuthHeader: null,
                userMisc: userMisc.value
            });
            // get firebase token from backend
            const fbToken = await firebaseAxios.backendCall();
            if (!fbToken.data.success)
                throw new Error('Firebase login failed');
            await firebaseLoginWithToken(fbToken.data.firebase_token);
            firebaseLoggedIn.value = true;
            return true;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }
        catch (error) {
            throw new Error('Firebase login failed');
        }
    }
    /*****
     * User
     * ****/
    const loggedIn = ref(false);
    const loggingIn = ref(false);
    const loggingOut = ref(false);
    const refreshingUser = ref(false);
    const refreshingUserCount = ref({
        date: new Date().toISOString(),
        count: 0
    });
    /**
     * Standardized method to update
     * data when user is logging in
     */
    function loginRequest(type, loading) {
        if (loading) {
            Loading.show({
                message: type === 'register'
                    ? 'Creating account'
                    : type === 'refresh'
                        ? 'Refreshing account'
                        : 'Logging in......',
                spinnerSize: 250,
                spinnerColor: 'accent'
            });
        }
        loggingOut.value = false;
        loggingIn.value = true;
    }
    /**
     * Flatten user details data for ease of updating
     * and saving to store
     * @param user_detail user details data
     * @returns flattened user details data
     */
    function flattenUserDetails(user_detail) {
        const detailsKeyMap = {
            'user_email_validated': 'email_validated',
            'user_pk': 'user_pk',
            'user_profile_img': 'profile_img',
            'user_profile_role_detail': 'profile_role_detail',
            'user_new_registration_chk': 'new_registration_chk',
            'user_ac_marked_for_deletion': 'ac_marked_for_deletion',
            'user_email': 'email',
            'user_enable_web_push_notif': 'enable_web_push_notif',
            'user_name': 'name',
            'user_profile_desc': 'profile_desc',
            'user_social_auth_detail': 'social_auth_detail'
        };
        const returnData = {};
        for (const key in user_detail) {
            if (!(key in detailsKeyMap))
                continue;
            const typedKey = key;
            // @ts-expect-error ignore
            returnData[detailsKeyMap[typedKey]] = user_detail[typedKey];
        }
        return returnData;
    }
    /**
     * Standardized way to remove tokens for web
     * type apps before saving to store.
     * @param param0
     * @returns user data to be saved in store
     */
    function setUser({ userData, type = 'login' }) {
        const isCapacitor = centralStore.platformInfo.capacitor || false;
        if (!isCapacitor &&
            ['login', 'register'].indexOf(type) > -1) {
            delete userData.access_token;
            delete userData.refresh_token;
        }
        delete userData.firebase_token;
        return userData;
    }
    /**
     * Split up user data between 2 sets to
     * reduce cookie size for cloudflare to work properly
     * @param data user data from backend
     * @param originalUserData user data in store
     * @param originalUserMiscData user misc data in store
     * @returns split up user data
     */
    function splitOutUserData(data, originalUserData, originalUserMiscData) {
        // specific data to store in the user store
        const userKeys = [
            'access_token_expires',
            'email_validated',
            'user_pk',
            'profile_img',
            'profile_role_detail',
            'remember'
        ];
        const combinedData = {
            ...cloneDeep(originalUserData),
            ...cloneDeep(originalUserMiscData),
            ...cloneDeep(data)
        };
        const splitUser = {};
        for (const key in combinedData) {
            const typedKey = key;
            if (userKeys.indexOf(typedKey) > -1) {
                // store specific data for users. for request header reduction
                // @ts-expect-error ignore
                splitUser[typedKey] = combinedData[typedKey];
            }
        }
        return {
            user: cloneDeep(splitUser),
            userMisc: cloneDeep(combinedData)
        };
    }
    /**
     * Update user data when more info comes in
     * @param param0 user data and if its capacitor
     */
    function updateUser({ data }) {
        const splitUserData = splitOutUserData(data, user.value || {}, userMisc.value || {});
        let userMiscUpdate = splitUserData.userMisc;
        userMiscUpdate = setUser({
            userData: userMiscUpdate,
            type: 'update'
        });
        user.value = splitUserData.user;
        userMisc.value = userMiscUpdate;
    }
    /**
     * Standard way to encrypt and
     * save user data
     * @param param0
     */
    function loginSuccess({ tokenData, pauseState = false }) {
        const envPassPhrase = process.env.passphrase;
        // encrypt token and save accordingly
        if (tokenData.firebase_token) {
            const backendFirebaseToken = tokenData.firebase_token;
            const encryptedFirebase = AES.encrypt(backendFirebaseToken, envPassPhrase).toString();
            delete tokenData.firebase_token;
            firebaseToken.value = encryptedFirebase;
        }
        if (tokenData.access_token) {
            const accessToken = tokenData.access_token;
            const encryptedAccess = AES.encrypt(accessToken, envPassPhrase).toString();
            tokenData.access_token = encryptedAccess;
        }
        if (tokenData.refresh_token) {
            const refreshToken = tokenData.refresh_token;
            const encryptedRefresh = AES.encrypt(refreshToken, envPassPhrase).toString();
            tokenData.refresh_token = encryptedRefresh;
        }
        // @ts-expect-error ignore
        tokenData = setUser({ userData: tokenData });
        const splitUserData = splitOutUserData(user.value || {}, userMisc.value || {}, tokenData);
        user.value = splitUserData.user;
        userMisc.value = splitUserData.userMisc;
        firebaseLoggedIn.value = true;
        refreshingUser.value = false;
        if (!pauseState) {
            loggedIn.value = true;
        }
    }
    const loginUrls = {
        'normal': 'user_auth/normal_login',
        'google': 'user_auth/google_login',
        'apple': 'user_auth/apple_login',
        'facebook': 'user_auth/facebook_login',
        'register': 'user_auth/register_user',
        'refresh': 'user_auth/refresh_access_token'
    };
    const loadingMessages = {
        'normal': 'Logging in....',
        'google': 'Logging in....',
        'apple': 'Logging in....',
        'facebook': 'Logging in....',
        'register': 'Registering your account...',
        'refresh': 'Refreshing account....'
    };
    /**
     * Standard login with username
     * and password
     * @param param0 username, password
     *  and whether to store user data
     *  for the longer term
     */
    async function login(data, type, loading = true) {
        loginRequest(type, loading);
        let userCall = null;
        try {
            // parse the data to be sent to backend
            // with the different types of logging in
            const axiosData = {
                ...(['normal', 'register'].indexOf(type) > -1
                    ? {
                        ...data,
                        ...('username' in data
                            ? {
                                username: data.username.toLowerCase().trim()
                            } : {})
                    } : {
                    ...data
                })
            };
            const userAxios = new AxiosDataParser({
                url: loginUrls[type],
                urlType: 'common',
                type: 'create',
                unrestricted: true,
                loading: loading,
                loadingMessage: loadingMessages[type],
                alert: true,
                message: 'Welcome back!',
                data: {
                    ...axiosData
                }
            }, {});
            userCall = await userAxios.backendCall();
        }
        catch (error) {
            Loading.hide();
            loggingIn.value = false;
            if (type !== 'refresh') {
                const errorData = error;
                const reason = errorData.response ? errorData.response.data.reason : '';
                const errorMsg = reason === 'access_denied The given email address is associated with another account'
                    ? 'Your account is not associated with this social login. Please log in with your username and password.'
                    : reason || 'Log in failed. Please log in again';
                errorAlert(errorMsg);
            }
        }
        // when call fails
        if (userCall === null || (userCall !== null &&
            userCall.success === false)) {
            if (type === 'refresh') {
                // if there are calls to be retried,
                // ask the user to login
                const hasRetry = centralStore.retryAxios.length > 0;
                if (hasRetry) {
                    viewStore.requireLogin({
                        required: true,
                        forced: true,
                        tab: 'login'
                    });
                }
                logout();
            }
            Loading.hide();
            // show error msg to user
            let errorMsg = 'Log in failed. Please log in again';
            if (userCall !== null &&
                userCall.error !== null &&
                userCall.error.reason !== undefined) {
                errorMsg = userCall.error.reason;
            }
            errorAlert(errorMsg);
            return { success: false, data: null };
        }
        /******
         * Update tokens
         *****/
        const userDetailsData = userCall.data;
        /******
         * Query user details from the backend
         * and parse data
         *****/
        if (!userDetailsData) {
            // user data missing. cant continue
            Loading.hide();
            loggingIn.value = false;
            errorAlert('Login failed');
            return { success: false, data: null };
        }
        const { 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        default_system_value_detail, host_detail, user_current_country_detail, user_token_detail, user_detail, ...otherUserData } = userDetailsData;
        /******
         * Firebase login
         *****/
        if (!user_token_detail.user_firebase_token) {
            // firebase token missing. cant continue
            Loading.hide();
            loggingIn.value = false;
            errorAlert('Invalid data');
            return { success: false, data: null };
        }
        const firebaseLoginStat = await firebaseLoginWithToken(user_token_detail.user_firebase_token);
        if (!firebaseLoginStat) {
            // firebase login fail
            Loading.hide();
            loggingIn.value = false;
            errorAlert('Firebase login failed');
            return { success: false, data: null };
        }
        delete user_token_detail.user_firebase_token;
        /******
         * Set data that is stored elsewhere in the store
         *****/
        setFlexCredits(user_detail.user_per_country_reward_detail);
        setBookings(user_detail.user_upcoming_no_of_booking || 0);
        setOrderReceivable(user_detail.user_order_pending_guest_review_count || 0);
        // host order data
        setOrder(host_detail.host_no_of_order_to_approve || 0);
        /******
         * Set user data to user store
         *****/
        const builtUserData = {
            ...otherUserData,
            // flatten data for tokens
            ...{
                access_token: user_token_detail.user_access_token,
                access_token_expires: user_token_detail.user_access_token_expires,
                refresh_token: user_token_detail.user_refresh_token,
            },
            // flatten data for user details
            ...flattenUserDetails(user_detail),
            // phone
            phone_details: {
                country_code: user_detail.user_phone_detail.phone_country_code,
                area_code: user_detail.user_phone_detail.phone_area_code,
                phone_num: user_detail.user_phone_detail.phone_num,
                disable_popups: user_detail.user_phone_detail.phone_disable_popups,
            },
            // country
            current_country_detail: {
                current_country_code: user_current_country_detail.user_current_country_code,
                current_country_currency: user_current_country_detail.user_current_country_currency,
                current_country_name: user_current_country_detail.user_current_country_name,
                current_country_pk: user_current_country_detail.user_current_country_pk,
            },
            // remember
            remember: 'remember' in data
                ? data.remember || false
                : true
        };
        /*****
         * Success! next steps
         *****/
        loginSuccess({
            tokenData: builtUserData,
            pauseState: false
        });
        Loading.hide();
        successAlert(`Welcome back, ${user_detail.user_name}!`);
        loggingIn.value = false;
        return { success: true, data: builtUserData };
    }
    async function refresh(loading = true) {
        if (refreshingUser.value)
            return;
        if (loggingOut.value)
            return;
        if (!user.value || !userMisc.value) {
            logout();
            return;
        }
        const dateDiff = new Date().getTime() - new Date(refreshingUserCount.value.date).getTime();
        // reset refresh count user refresh after 1 hour
        if (dateDiff > 1000 * 60 * 60 * 1) {
            refreshingUserCount.value = {
                date: new Date().toISOString(),
                count: 0
            };
        }
        if (refreshingUserCount.value.count >= 2) {
            errorAlert('Refreshing account failed. Please login again');
            logout();
            return;
        }
        refreshingUser.value = true;
        let originalToken = null;
        if (userMisc.value.refresh_token) {
            const encryptedToken = userMisc.value.refresh_token;
            originalToken = encryptedToken;
            const decryptedToken = AES.decrypt(encryptedToken, process.env.passphrase).toString(ENC);
            if (decryptedToken) {
                originalToken = decryptedToken;
            }
        }
        refreshingUserCount.value = {
            ...refreshingUserCount.value,
            count: refreshingUserCount.value.count + 1
        };
        if (centralStore.platformInfo.capacitor && !originalToken) {
            errorAlert('Refreshing account failed. Please login again');
            logout();
            return;
        }
        const { success } = await login({
            username: userMisc.value.email || '',
            ...(centralStore.platformInfo.capacitor
                ? {
                    user_refresh_token: originalToken
                } : {})
        }, 'refresh', loading);
        return { success };
    }
    /**
     * Sets log out state
     */
    function logout() {
        if (loggingOut.value)
            return;
        loggingOut.value = true;
    }
    /**
     * When user logs out, clear data
     * from other tabs if multiple tabs
     * are open
     */
    function otherTabLogout() {
        user.value = null;
        userMisc.value = null;
        firebaseToken.value = null;
    }
    /**
     * When tab in focus completes the
     * logout and data deletion
     */
    function loggedOut() {
        user.value = null;
        userMisc.value = null;
        firebaseToken.value = null;
        loggedIn.value = false;
        loggingOut.value = false;
        refreshingUserCount.value = {
            date: new Date().toISOString(),
            count: 0
        };
    }
    const timeToExpire = ref(0);
    /**
     * Set number of ms to expiry
     * @param data number of ms to expirty
     */
    function toggleTte(data) {
        timeToExpire.value = data;
    }
    /*****
     * Calendar
     * ****/
    const gSignin = ref([]);
    /**
     * When user logs in to 1 way sync the calendar
     * @param data token data
     */
    function setGTokens(data) {
        const stateCopy = [...gSignin.value];
        let emailExists = false;
        for (let i = 0; i < stateCopy.length; i++) {
            if (stateCopy[i].email !== data.email)
                continue;
            // update token
            stateCopy[i].token = data.token;
            emailExists = true;
            break;
        }
        if (!emailExists) {
            stateCopy.push(data);
        }
        gSignin.value = stateCopy;
    }
    /*****
     * Display count for users to respond
     * ****/
    const hostOrder = ref(0);
    /**
     * @param data number orders pending host approval
     */
    function setOrder(data) {
        hostOrder.value = data;
    }
    const custBookings = ref(0);
    /**
     * @param data Number of upcoming bookings the user has
     */
    function setBookings(data) {
        custBookings.value = data;
    }
    const orderReceivable = ref(0);
    /**
     * @param data number of rebates receivable by the
     *  user when the user leaves a review
     */
    function setOrderReceivable(data) {
        orderReceivable.value = data;
    }
    const unreadChat = ref(0);
    /**
     * @param data Number of unread chats
     */
    function setUnreadChat(data) {
        unreadChat.value = data;
    }
    const unreadNotifications = ref(0);
    /**
     * @param data Number of new notifications
     */
    function setUnreadNotif(data) {
        unreadNotifications.value = data;
    }
    const flexCredits = ref([]);
    /**
     * @param data Number of credits that can be used
     * to book flexi bookings
     */
    function setFlexCredits(data) {
        flexCredits.value = data;
    }
    const currentCountryFlexCredits = computed(() => {
        for (let i = 0; i < flexCredits.value.length; i++) {
            const item = flexCredits.value[i];
            const currentCountryCode = userMisc.value?.current_country_detail?.current_country_code || "SG";
            if (item?.country_code === currentCountryCode) {
                return item;
            }
            continue;
        }
        return {
            country_code: userMisc.value?.current_country_detail?.current_country_code || "SG",
            country_currency: userMisc.value?.current_country_detail?.current_country_currency || "SGD",
            country_reward_bal: ""
        };
    });
    return {
        firebaseLoggedIn,
        firebaseToken,
        toggleFirebaseLoggedIn,
        firebaseLogin,
        flattenUserDetails,
        user,
        updateUser,
        userMisc,
        loggedIn,
        loggingIn,
        loggingOut,
        refreshingUser,
        refreshingUserCount,
        login,
        logout,
        loggedOut,
        otherTabLogout,
        refresh,
        timeToExpire,
        toggleTte,
        gSignin,
        setGTokens,
        hostOrder,
        setOrder,
        custBookings,
        setBookings,
        orderReceivable,
        setOrderReceivable,
        unreadChat,
        setUnreadChat,
        unreadNotifications,
        setUnreadNotif,
        flexCredits,
        currentCountryFlexCredits,
        setFlexCredits,
    };
});
