import { defineBoot } from '#q-app/wrappers';
import { createI18n } from 'vue-i18n';
import { messages } from 'src/plugins/i18n/global';
export default defineBoot(({ app, store }) => {
    const defaultLocale = store.state.value.central.locale;
    // Create VueI18n instance with options
    const i18n = createI18n({
        locale: defaultLocale || 'en-US',
        legacy: false,
        messages
    });
    // Set i18n instance on app
    app.use(i18n);
});
