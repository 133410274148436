// Stores for threading
// This is used to centralize the communication with js workers
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAccountStore } from './account';
import { useCentralStore } from './central';
import { useLoggingStore } from './logging';
import { AxiosDataParser } from 'src/plugins/axios/axiosParser';
import { authHeader } from 'src/plugins/axios/authHeader';
import { tteCalculator } from 'src/features/account/methods/tteCalculator';
import { v4 as uuidv4 } from 'uuid';
import Loading from 'quasar/src/plugins/loading/Loading.js';
export const useThreadingStore = defineStore('threading', () => {
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const loggingStore = useLoggingStore();
    /*******
     * Store data
     ******/
    const poolSize = ref(4);
    /**
     * Set the number of cpu cores that can be
     * used by workers. Which is the upper limit
     * of concurrent threads that can be run
     * @param payload number of cpu cores
     */
    function setPoolSize(payload) {
        poolSize.value = payload;
    }
    // current number of running tasks
    const runningTaskCount = ref(0);
    /**
     * When new workers are created
     */
    function threadIncrease() {
        runningTaskCount.value = runningTaskCount.value + 1;
    }
    /**
     * When worker's task is completed
     */
    function threadDecrease() {
        runningTaskCount.value = runningTaskCount.value - 1;
    }
    // tasks that are waiting for space to be freed up
    const taskQueue = ref([]);
    function addTaskQueue(queue) {
        taskQueue.value = [
            ...taskQueue.value,
            queue
        ];
    }
    function updateTaskQueue(queue) {
        taskQueue.value = queue;
    }
    // record tasks that are currently running
    const loading = ref([]);
    /**
     * remove tasks from loading
     * @param task key of the thread that is running
     */
    function removeRunningTask(task) {
        const taskIndex = loading.value.indexOf(task);
        if (taskIndex === -1)
            return;
        const loadingCopy = [...loading.value];
        loadingCopy.splice(taskIndex, 1);
        loading.value = loadingCopy;
    }
    /**
     * add task to the list of running tasks
     * @param task key of the thread that is running
     */
    function addRunningTask(task) {
        if (loading.value.indexOf(task) > -1)
            return;
        loading.value = [
            ...loading.value,
            task
        ];
    }
    /**
     * Push next tasks that are in queue to the worker
     * if there is next tasks and the number of active
     * worker is less than the pool size
     */
    function nextInQueue() {
        if (taskQueue.value.length === 0)
            return;
        if (runningTaskCount.value >= poolSize.value)
            return;
        const taskQueueCopy = [
            ...taskQueue.value
        ];
        const newTask = taskQueueCopy.shift();
        // @ts-expect-error ignore
        threadTasks(newTask);
        updateTaskQueue(taskQueueCopy);
    }
    // data that requires additional
    // calculation
    const parsedData = ref({});
    /**
     * Clear calculated data from store
     * @param type task key
     */
    function clearParsedData(type) {
        const parsedDataCopy = { ...parsedData.value };
        delete parsedDataCopy[type];
        parsedData.value = parsedDataCopy;
    }
    /**
     * When worker returns the parsed data
     * @param param0 parsed data with task key
     */
    function parsedSuccess({ type, jsonData }) {
        const threadedData = { ...parsedData.value };
        jsonData.thread_id = uuidv4();
        threadedData[type] = jsonData;
        parsedData.value = threadedData;
    }
    // data from backend
    const data = ref({});
    /**
     * clear backend returned data from store
     * @param type task key
     */
    function clearThreadedData(type) {
        const dataCopy = { ...data.value };
        delete dataCopy[type];
        data.value = dataCopy;
    }
    /**
     * When worker returns the backend data
     * @param param0 backend data with task key
     */
    function threadSuccess({ type, jsonData, isLoading }) {
        const threadedData = { ...data.value };
        threadedData[type] = jsonData;
        data.value = threadedData;
        if (isLoading && !process.env.SERVER) {
            Loading.hide();
        }
    }
    const error = ref({});
    /**
     * Clear error data from store
     * @param type task key
     */
    function clearError(type) {
        const errorDataCopy = { ...error.value };
        delete errorDataCopy[type];
        error.value = errorDataCopy;
    }
    /**
     * When the worker fails. store the error
     * @param data error data with task key
     */
    function threadFail(data) {
        const threadedErr = { ...error.value };
        threadedErr[data.endState] = data.error;
        error.value = threadedErr;
        if (!process.env.SERVER) {
            Loading.hide();
        }
    }
    /**
     * Clear all data in accordance to the key
     * @param type key of task
     */
    function clearData(type) {
        clearThreadedData(type);
        clearParsedData(type);
        clearError(type);
    }
    /*******
     * Worker tasking
     ******/
    function workerSuccess({ worker = null, endState, event, type = 'parse', loading = false }) {
        const data = event.data;
        if (type === 'parse') {
            parsedSuccess({
                type: endState,
                jsonData: data
            });
        }
        else {
            threadSuccess({
                type: endState,
                jsonData: data,
                isLoading: loading
            });
        }
        removeRunningTask(endState);
        if (worker) {
            threadDecrease();
            worker.terminate();
        }
        // push next task to worker
        nextInQueue();
    }
    function workerFail({ worker = null, endState, event }) {
        threadFail({ endState, error: event });
        removeRunningTask(endState);
        loggingStore.errorHandler(event);
        if (worker) {
            threadDecrease();
            worker.terminate();
        }
        // push next task to worker
        nextInQueue();
        // throw event
    }
    /**
     * Parse bookings from backend to display in calendar page
     * @param param0 data, endstate and if app is capacitor
     */
    async function parseCalendar({ data, endState, isCapacitor }) {
        const dataWithLocale = {
            ...data,
            localeStr: centralStore.locale
        };
        if (process.env.SERVER || isCapacitor) {
            const { calendarParser } = await import('src/_helpers/common/calendarParser');
            // @ts-expect-error ignore
            const parsedCalendar = calendarParser(dataWithLocale);
            workerSuccess({
                worker: null,
                endState,
                event: { data: parsedCalendar },
                type: 'parse',
                loading: false
            });
            return;
        }
        const calWorker = new Worker(new URL('./workers/parseCalendar.js', import.meta.url));
        calWorker.onmessage = (event) => {
            void workerSuccess({
                worker: calWorker,
                endState,
                event,
                type: 'parse',
                loading: false
            });
        };
        calWorker.onerror = (event) => {
            workerFail({
                worker: calWorker,
                endState,
                event
            });
        };
        calWorker.postMessage(JSON.parse(JSON.stringify(dataWithLocale)));
        threadIncrease();
    }
    /**
     * Parse bookings from backend to display in calendar page
     * @param param0 data, endstate and if app is capacitor
     */
    async function parseTimings({ data, endState, isCapacitor }) {
        const dataWithLocale = {
            ...data,
            localeStr: centralStore.locale
        };
        if (process.env.SERVER || isCapacitor) {
            const { timingLoader } = await import('src/_helpers/common/timingLoader');
            // @ts-expect-error ignore
            const parseTiming = timingLoader(dataWithLocale);
            workerSuccess({
                worker: null,
                endState,
                event: { data: parseTiming },
                type: 'parse',
                loading: false
            });
            return;
        }
        const timingWorker = new Worker(new URL('./workers/parseTimings.js', import.meta.url));
        timingWorker.onmessage = (event) => {
            void workerSuccess({
                worker: timingWorker,
                endState,
                event,
                type: 'parse',
                loading: false
            });
        };
        timingWorker.onerror = (event) => {
            workerFail({
                worker: timingWorker,
                endState,
                event
            });
        };
        timingWorker.postMessage(JSON.parse(JSON.stringify(dataWithLocale)));
        threadIncrease();
    }
    /**
     * Parse bookings from backend to display in calendar page
     * @param param0 data, endstate and if app is capacitor
     */
    async function threadedAPI({ data, endState, isCapacitor }) {
        const timeToExpire = tteCalculator(accountStore.user);
        const axiosLib = new AxiosDataParser(data, {
            timeToExpire,
            customAuthHeader: null,
            userMisc: accountStore.userMisc
        });
        const { loading, loadingMessage } = axiosLib.axiosLoading();
        if (!process.env.SERVER && loading) {
            Loading.show({
                message: loadingMessage || "",
                spinnerSize: 250,
                spinnerColor: 'accent'
            });
        }
        // if data is already in queue for retrying, skip
        let isInRetry = false;
        for (let i = 0; i < centralStore.retryAxios.length; i++) {
            const retryCall = centralStore.retryAxios[i];
            if (endState === retryCall.data.endState) {
                isInRetry = true;
                break;
            }
        }
        if (isInRetry) {
            return;
        }
        // skip query if user is in
        // an indeterminate state
        if (accountStore.refreshingUser ||
            accountStore.loggingIn ||
            accountStore.loggingOut ||
            !centralStore.online ||
            !centralStore.backendOnline) {
            centralStore.pushToRetryQueue({
                id: uuidv4(),
                type: 'thread',
                data: { data, endState }
            });
            return;
        }
        if (process.env.SERVER || isCapacitor) {
            try {
                const axiosResponse = await axiosLib.backendCall();
                const axiosError = axiosResponse.error;
                if (axiosError) {
                    if (([401, 403, 408].indexOf(axiosError.code) > -1 || (typeof axiosError.message === 'string' &&
                        axiosError.message === 'User is not authenticated')) && endState !== 'init') {
                        if (axiosError.code === 408) {
                            // if 408, just retry
                            centralStore.toggleBackendOnline(false);
                        }
                        else {
                            // query init endpoint to check if user is authenticated
                            // or refresh token if necessary
                            centralStore.setRequeryInit();
                        }
                        centralStore.pushToRetryQueue({
                            id: uuidv4(),
                            type: 'thread',
                            data: { data, endState }
                        });
                    }
                    workerFail({
                        worker: null,
                        endState,
                        event: axiosResponse.error
                    });
                    return;
                }
                workerSuccess({
                    worker: null,
                    endState,
                    event: {
                        data: {
                            ...(axiosResponse.data || {}),
                            ...(data.queryKey ? {
                                queryKey: data.queryKey
                            } : {}),
                            revision: 1,
                            thread_id: uuidv4(),
                            loading: loading
                        }
                    },
                    type: 'thread',
                    loading
                });
            }
            catch (error) {
                workerFail({
                    worker: null,
                    endState,
                    event: error
                });
            }
            return;
        }
        addRunningTask(endState);
        const authHeaderVal = authHeader(['list', 'retrieve'].indexOf(data.type || '') > -1, accountStore.userMisc);
        const axiosWorker = new Worker(new URL('./workers/axiosWorker.js', import.meta.url));
        axiosWorker.onmessage = (event) => {
            const axiosError = event.data.error;
            if (axiosError) {
                if (([401, 403, 408].indexOf(axiosError.code) > -1 || (typeof axiosError.message === 'string' &&
                    axiosError.message === 'User is not authenticated')) && endState !== 'init') {
                    if (axiosError.code === 408) {
                        // if 408, just retry
                        centralStore.toggleBackendOnline(false);
                    }
                    else {
                        // query init endpoint to check if user is authenticated
                        // or refresh token if necessary
                        centralStore.setRequeryInit();
                    }
                    centralStore.pushToRetryQueue({
                        id: uuidv4(),
                        type: 'thread',
                        data: { data, endState }
                    });
                }
                workerFail({
                    worker: axiosWorker,
                    endState,
                    // @ts-expect-error ignore
                    event: event.data.error
                });
                return;
            }
            void workerSuccess({
                worker: axiosWorker,
                endState,
                event: event.data,
                type: 'thread',
                loading
            });
        };
        axiosWorker.onerror = (event) => {
            workerFail({
                worker: axiosWorker,
                endState,
                event
            });
        };
        axiosWorker.postMessage(JSON.parse(JSON.stringify({
            data,
            timeToExpire,
            authHeaderVal,
            loading: data.loading || false
        })));
        threadIncrease();
    }
    function threadTasks({ data, endState, type }) {
        const isCapacitor = centralStore.platformInfo.capacitor || false;
        if (runningTaskCount.value >= poolSize.value) {
            addTaskQueue({ data, endState, type });
            return;
        }
        if (loading.value.indexOf(endState) > -1)
            return;
        if (type === 'api') {
            // @ts-expect-error ignore
            void threadedAPI({ data, endState, isCapacitor });
        }
        else if (type === 'timings') {
            void parseTimings({ data, endState, isCapacitor });
        }
        else if (type === 'calendar') {
            void parseCalendar({ data, endState, isCapacitor });
        }
    }
    return {
        setPoolSize,
        nextInQueue,
        parsedData,
        clearParsedData,
        data,
        clearThreadedData,
        error,
        clearError,
        clearData,
        threadTasks,
        workerSuccess
    };
});
