import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { debounceTime, filter, tap, } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { useCentralStore } from 'src/stores/central';
import { RefreshPagePlugin } from 'src/plugins/general/refreshPage';
import { AppGeneralVariables } from 'src/plugins/general/variables';
import { SetMetaPlugin } from 'src/plugins/meta/setMeta';
import { parsePrivatePages } from 'src/plugins/general/privatePages';
import { MetaBuilderLibrary } from 'src/plugins/meta/metaBuilder';
import cloneDeep from 'lodash.clonedeep';
import { ModeSwitcher } from 'src/plugins/meta/modeSwitcher';
/**
 * Plugin to set meta for the GetSpaces app
 */
const AppBaseMetaPlugin = () => {
    const $q = useQuasar();
    const vueRoute = useRoute();
    const { StoreLink, StoreMeta, StoreScript, setMetaMeta, setMetaLink, setMetaTitle } = SetMetaPlugin();
    /**
     * Set robots.txt whether to index the page
     */
    function setRobots() {
        const routeName = String(vueRoute.name);
        const metaCopy = cloneDeep(StoreMeta.value);
        const { privatePages } = parsePrivatePages();
        metaCopy.robots.content = (privatePages.indexOf(routeName) === -1) && (process.env.prodType === 'prod')
            ? 'all'
            : 'noindex';
        setMetaMeta(metaCopy);
    }
    setRobots();
    /**
     * Set viewport value. Special for iOS users
     * @returns
     */
    function setViewport() {
        if (!$q.platform.is.ios)
            return;
        const metaCopy = cloneDeep(StoreMeta.value);
        metaCopy.viewport.content = `${metaCopy.viewport.content},maximum-scale=1`;
        setMetaMeta(metaCopy);
    }
    setViewport();
    /**
     * set meta for SEO. For pages without
     * their own specific meta data
     */
    function fixMeta() {
        const routeName = String(vueRoute.name);
        const { privatePages } = parsePrivatePages();
        // do not set data
        if ([
            'Generic Landing Pages',
            'Home',
            'Listing',
            'Property',
            'Host Profile',
            'Host Spaces For Rent',
            'Location Activities Search',
            'Location Search',
            'Search',
        ].indexOf(routeName) > -1 ||
            typeof routeName === 'undefined') {
            return;
        }
        const url = `https://${process.env.domain}${vueRoute.path}`;
        const parsedTitle = `${routeName} | GetSpaces`;
        // Add unique description here for certain pages
        const routeNameDesc = {
            About: 'GetSpaces is an online marketplace that connects people with the right spaces. Our mission is to build a transparent marketplace where people can easily find the spaces they need.',
            'Contact Us': `Can't find the space you need? Need help navigating the platform?
        Need custom services? Don't worry! Speak to our friendly customer service and we will be here for you!`,
            'Account Rewards': `
        Earn rewards as you book with GetSpaces! Receive credits
        for your next booking after you complete the booking and leave
        a review
      `,
            'Policies Page': 'Policies and Terms of Use of the GetSpaces Platform',
            Sitemap: 'Sitemap for all pages on the GetSpaces Platform',
        };
        const metaLib = new MetaBuilderLibrary({
            currentLink: StoreLink.value,
            currentMeta: StoreMeta.value,
            currentScript: StoreScript.value,
        }, typeof routeNameDesc[routeName] !== 'undefined'
            ? routeNameDesc[routeName]
            : '');
        metaLib.setLink({
            pageId: vueRoute.path,
            url,
            images: [],
            imageMeta: {
                originalSize: '1280_',
                sizeType: 'landing_page',
                imageSizes: ''
            },
            preloadCount: 0,
        });
        metaLib.setMeta({
            active: privatePages.indexOf(routeName) === -1,
            images: [],
            name: parsedTitle,
            url
        });
        // set unique title as a baseline
        setMetaTitle(parsedTitle);
        // set unique canonical for seo
        setMetaLink(metaLib.getLink());
        // <meta> tag related
        setMetaMeta(metaLib.getMeta());
    }
    /******
     * When to trigger default meta
     ******/
    const { subject: debouncedFixMeta$ } = rxInitSubjectCallback();
    useObservable(debouncedFixMeta$.pipe(debounceTime(300), tap(() => {
        fixMeta();
    })));
    watch(vueRoute, () => {
        // update page meta according to the route
        // to change the titles
        debouncedFixMeta$.next('');
    });
    /******
     * Flush preload images after they
     * are preloaded to prevent page bloat
     ******/
    const centralStore = useCentralStore();
    const preloadFlushList = computed(() => {
        return centralStore.preloadImageList;
    });
    const { subject: flushPreload$ } = rxInitSubjectCallback();
    useObservable(flushPreload$.pipe(debounceTime(300), tap((v) => {
        const linkClone = cloneDeep(StoreLink.value);
        for (let i = 0; i < v.length; i++) {
            delete linkClone[v[i]];
        }
        setMetaLink(linkClone);
    })));
    watch(preloadFlushList, (newValue) => {
        flushPreload$.next(newValue);
    });
    /****
     * When app mode is switched
     *****/
    const vueRouter = useRouter();
    const { appMode } = AppGeneralVariables();
    const { refreshPage } = RefreshPagePlugin();
    const { switchMode } = ModeSwitcher();
    const { subject: appModeWatcher$ } = rxInitSubjectCallback();
    useObservable(appModeWatcher$.pipe(filter(({ oldValue }) => oldValue !== null), filter(({ newValue, oldValue }) => oldValue !== newValue), tap(() => {
        switchMode();
        if (vueRoute.name !== 'Home') {
            void vueRouter.push({ name: 'Home' });
        }
    }), debounceTime(100), tap(() => {
        refreshPage();
    })));
    watch(appMode, (newValue, oldValue) => {
        appModeWatcher$.next({ newValue, oldValue });
    });
};
export { AppBaseMetaPlugin };
