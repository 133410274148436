import { errorParams, successParams } from './baseData';
import Notify from 'quasar/src/plugins/notify/Notify.js';
/**
 * Standard ways to show alerts
 * @returns {
 *  errorAlert, successAlert
 * }
 */
const StandardAlertsPlugin = () => {
    function successAlert(message) {
        // @ts-expect-error ignore
        Notify.create({
            message: message,
            ...successParams
        });
    }
    function errorAlert(message) {
        let msg = '';
        if (typeof message == 'string') {
            Notify.create({
                message,
                ...errorParams
            });
            return;
        }
        if (message.request) {
            const { request } = message;
            if (request.status === 0) {
                msg = 'Network Error. Please Try Again';
            }
            else {
                try {
                    const response = message.request?.response;
                    const parsedResponse = JSON.parse(response);
                    msg = parsedResponse.reason ||
                        parsedResponse.detail ||
                        'Server Error';
                }
                catch {
                    msg = 'Server Error';
                }
            }
        }
        else if (message.message) {
            const { message: msgData } = message;
            if (msgData.toLowerCase().indexOf('permission') > -1) {
                msg = 'Please Log In Or Refresh Browser To Continue';
            }
            else {
                msg = msgData;
            }
        }
        Notify.create({
            message: msg,
            ...errorParams
        });
    }
    return {
        successAlert,
        errorAlert,
    };
};
export { StandardAlertsPlugin };
