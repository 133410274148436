import { computed } from 'vue';
import { useAccountStore } from 'src/stores/account';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';import date from 'quasar/src/utils/date/date.js';
import { v4 as uuidv4 } from 'uuid';
import { AppGeneralVariables } from 'src/plugins/general/variables';
const { formatDate } = date;
function gtag(...args) {
    if (typeof window !== 'undefined') {
        window.dataLayer.push(args);
    }
}
/**
 * Library for google analytics
 * custom event tags
 */
const GoogleAnalyticsTrack = () => {
    const accountStore = useAccountStore();
    const $q = useQuasar();
    const { appMode } = AppGeneralVariables();
    const user = computed(() => accountStore.user);
    /*********
     * User
     ********/
    /**
     * When host registers as a host,
     * push to dataLayer
     */
    function newHostRegistration() {
        window?.dataLayer.push({
            event: 'host_registration',
            custom_evt_name: 'host_registration',
            user_id: user.value ? user.value.user_pk.toString() : null,
            app_type: appMode.value,
        });
    }
    /**
     * When new user registers, push to data layer
     * @param userId user ID of new user
     */
    function newUserRegistration(userId) {
        window?.dataLayer.push({
            event: 'new_user_registration',
            custom_evt_name: 'new_user_registration',
            user_id: userId.toString(),
            app_type: appMode.value
        });
    }
    /**
     * Push user ID to data layer
     */
    function userTagging() {
        if (user.value) {
            window?.dataLayer.push({
                event: 'set_user_id',
                custom_evt_name: 'set_user_id',
                user_id: (user.value?.user_pk || '').toString(),
                app_type: appMode.value
            });
        }
        else {
            window?.dataLayer.push({
                userId: null,
                app_type: appMode.value
            });
        }
    }
    /*********
     * Chat
     ********/
    /**
     * When user fills in the generate chat dialog
     * to generate chat room
     */
    function chatGenerated(listingId, listingName) {
        window?.dataLayer.push({
            event: 'listing_enquiry',
            custom_evt_name: 'listing_enquiry',
            user_id: user.value.user_pk.toString(),
            listing_id: listingId,
            listing_name: listingName,
            app_type: appMode.value
        });
    }
    /**
     * When user clicks on msg host
     */
    function userMsgHost() {
        window?.dataLayer.push({
            event: 'Message Host',
            chat_click: 1,
            ...(user.value
                ? {
                    user_id: user.value.user_pk.toString()
                } : {
                user_id: ''
            })
        });
    }
    /*********
     * Flex listing
     ********/
    /**
     * When app listing is shown
     * @param listingId listing ID
     * @param listingName listing Name
     */
    function showAppListing(listingId, listingName) {
        if (process.env.SERVER)
            return;
        window?.dataLayer.push({
            event: 'view_listing',
            custom_evt_name: 'view_listing',
            user_id: user.value ? user.value.user_pk.toString() : null,
            listing_id: listingId,
            listing_name: listingName,
            app_type: appMode.value,
            items: [
                {
                    id: listingId,
                    item_id: listingId,
                    item_name: listingName,
                    content_ids: [listingId],
                },
            ],
        });
    }
    /**
     * When user view listing in capacitor
     * @param params listing id params
     * @param routeName route name
     * @returns void
     */
    function showAppListingRoute(params, routeName) {
        if (process.env.SERVER)
            return;
        if (!$q.platform.is.capacitor)
            return;
        if (process.env.prodType !== 'prod')
            return;
        let sessionId = $q.sessionStorage.getItem('GA_ID');
        if (!sessionId) {
            const uniqueId = uuidv4();
            const startDate = formatDate(new Date(), 'YYYYMMDD');
            sessionId = `${startDate}|${uniqueId}`;
            $q.sessionStorage.set('GA_ID', sessionId);
        }
        window?.dataLayer.push({
            screenPath: `/${routeName.toLowerCase()}/${params}`,
            screenName: routeName,
            sessionId: sessionId,
        });
        window?.dataLayer.push({ event: 'appScreenView' });
    }
    /**
     * Purchase success
     * @param listingId listing id
     * @param listingName listing id
     * @param orderNum order num from backend
     * @param value total order value
     * @param currency currency of order
     * @param appType app type of order
     */
    function purchaseSuccessGtag(listingId, listingName, orderNum, value, currency = 'SGD', appType = 'getspaces') {
        window?.dataLayer.push({
            event: 'book_success',
            custom_evt_name: 'book_success',
            venue_id: listingId || '',
            venue_name: listingName || '',
            order_id: orderNum,
            app_type: appType,
            value,
            currency: currency,
            ...(user.value
                ? {
                    user_id: user.value.user_pk.toString()
                } : {
                user_id: ''
            })
        });
    }
    /**
     * When user clicks on
     * the view landmark on the listing page
     */
    function landmarkClick() {
        window?.dataLayer.push({
            event: 'Landmark Click',
            landmarks: 1,
            ...(user.value
                ? {
                    user_id: user.value.user_pk.toString()
                } : {})
        });
    }
    /*********
     * Property
     ********/
    /**
     * When property form is submitted
     * @param purpose property purpose
     * @param category categoru of the enquiry form
     */
    function enquiryFormSubmit(purpose, category) {
        window?.dataLayer.push({
            event: 'property_form_submit',
            custom_evt_name: 'property_form_submit',
            user_id: (user.value?.user_pk || '').toString(),
            purpose: purpose,
            form_category: category
        });
    }
    /**
     * When property lead is added to cart
     * @param value value of lead
     * @param leadNum lead id
     * @param currency currency of the lead
     */
    function propertyLeadAddToCart(leadId, value, currency = 'SGD') {
        window?.dataLayer.push({
            event: 'property_lead_add_to_cart',
            custom_evt_name: 'property_lead_add_to_cart',
            user_id: (user.value?.user_pk || '').toString(),
            app_type: 'getspaces',
            lead_id: leadId,
            value,
            currency: currency,
        });
    }
    /**
     * When property lead is purchased
     * @param value value of lead
     * @param leadNum lead id
     * @param currency currency of the lead
     */
    function propertyLeadPurchase(orderId, value, currency = 'SGD') {
        window?.dataLayer.push({
            event: 'property_lead_purchase',
            custom_evt_name: 'property_lead_purchase',
            user_id: (user.value?.user_pk || '').toString(),
            app_type: 'getspaces',
            lead_order_id: orderId,
            value,
            currency: currency,
        });
    }
    return {
        newHostRegistration,
        userTagging,
        newUserRegistration,
        chatGenerated,
        userMsgHost,
        showAppListing,
        showAppListingRoute,
        purchaseSuccessGtag,
        landmarkClick,
        // property
        enquiryFormSubmit,
        propertyLeadAddToCart,
        propertyLeadPurchase
    };
};
export { GoogleAnalyticsTrack, gtag };
