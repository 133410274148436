// Conversations and inbox store
import { defineStore } from 'pinia';
import { ref } from 'vue';
import date from 'quasar/src/utils/date/date.js';
const { getDateDiff } = date;
import { redactSensitiveInfo } from 'src/features/messaging/methods/redactMsg';
export const useConvoStore = defineStore('conversations', () => {
    /******
     * Chat
     *****/
    // chat data
    const all = ref({});
    /**
     * set chat room data with basic info
     * @param param0 chat room data
     */
    function parseChatRoom({ data, roomID }) {
        all.value = {
            ...all.value,
            [roomID]: {
                users: data.users,
                created: data.created,
                listing_id: data.listing_id,
                messages: []
            }
        };
    }
    const currentConvo = ref({
        id: '',
        receiver_pic: '',
        receiver_name: '',
        listing_name: ''
    });
    /**
     * Enter chat room
     * @param param0 convo data
     */
    function setCurrentConversation({ id, receiverPic, receiverName, listingName }) {
        currentConvo.value = {
            id: id,
            receiver_pic: receiverPic,
            receiver_name: receiverName,
            listing_name: listingName
        };
    }
    /**
     * When messages are queried,
     * parse and add to state
     * @param param0 message data
     * @returns
     */
    function addMessagesToList({ conversationId, message }) {
        const allCopy = { ...all.value };
        const messageId = message.id;
        if (!messageId)
            return;
        const targetMessages = allCopy[conversationId].messages;
        const msgIndex = targetMessages.findIndex((item) => item.id === messageId);
        // if message is already in state, do not add
        // unless there is data to update
        if ((msgIndex > -1) &&
            !message.has_sensitive_info &&
            !message.read &&
            (message.type !== 'custom_order'))
            return;
        // for new msg, just push into state
        if (msgIndex === -1) {
            targetMessages.push(message);
        }
        else {
            // update data for existing messages
            targetMessages[msgIndex] = {
                ...targetMessages[msgIndex],
                ...{
                    ...(message.has_sensitive_info
                        ? {
                            has_sensitive_info: message.has_sensitive_info
                        } : {}),
                    ...(message.type === 'custom_order'
                        ? { status: message.status }
                        : {}),
                    ...(message.read
                        ? { read: message.read }
                        : {})
                }
            };
        }
        // sort messages according to created date
        targetMessages.sort((a, b) => {
            return (a.createdAt.toDate().getTime() -
                b.createdAt.toDate().getTime());
        });
        all.value = allCopy;
    }
    /**
     * When user exits the chat room. clear all data
     * @param collection chat room type. usually 'chat'
     * @param userId user ID
     */
    async function clearConversation(collection, userId) {
        if (!collection || !userId)
            return;
        // import
        const firebaseModule = await import(
        /* webpackChunkName: "firebaseAuth" */
        'src/_helpers/firebaseMethods');
        const { db } = await firebaseModule.default();
        const { doc, updateDoc } = await import(
        /* webpackChunkName: "firebaseAuth" */
        'firebase/firestore');
        const currentConvoId = currentConvo.value.id;
        void updateDoc(doc(db, collection, currentConvoId), {
            [`typing_${userId}`]: false,
            [`is_active_${userId}`]: false
        });
        if (!all.value[currentConvoId])
            return;
        all.value[currentConvoId].messages = [];
    }
    /******
     * Inbox related
     *****/
    const inbox = ref({});
    /**
     * Parse last message date to readable text
     * @param lastMsgDateVal last message date
     * @returns readable date txt
     */
    function parsedDate(lastMsgDateVal) {
        if (!lastMsgDateVal)
            return '';
        const mins = getDateDiff(new Date(), new Date(lastMsgDateVal), 'minutes');
        return mins <= 60
            ? `${mins} mins ago`
            : mins <= (60 * 24)
                ? `${Number(mins / 60).toFixed(0)} hours ago`
                : `${Number(mins / (60 * 24)).toFixed(0)} days ago`;
    }
    /**
     * Parse inbox data from firestore
     * for data to be used in frontend
     * @param param0
     */
    function setConvo({ convos, currUserID }) {
        const allConvo = { ...all.value };
        const inboxCopy = { ...inbox.value };
        const toSort = [];
        const newIds = [];
        convos.forEach((doc) => {
            const convoId = doc.id;
            const data = doc.data();
            const dateTxt = parsedDate(data.last_message_date);
            const receiverId = data.users.filter(item => {
                return Number(item) !== Number(currUserID);
            })[0];
            const redactMsg = redactSensitiveInfo(data.last_message);
            // update chat room data on the chat level
            // so that data can be shown while convo is loading
            allConvo[convoId] = {
                ...(allConvo[convoId] || {
                    messages: []
                }),
                ...{
                    id: convoId,
                    created: data.created,
                    createdAt: data.createdAt,
                    date_txt: dateTxt,
                    has_pending_order: data.has_pending_order,
                    last_active: data.last_active,
                    last_sent: redactMsg,
                    listing_id: data.listing_id,
                    receiver_id: receiverId,
                    unread: data[`unread_${currUserID}`],
                    users: data.users,
                }
            };
            if (convoId in inboxCopy) {
                // update inbox data
                toSort.push({
                    ...inboxCopy[convoId],
                    ...{
                        messages: [redactMsg],
                        last_sent: data.last_message_date,
                        unread: data[`unread_${currUserID}`],
                        last_active: data.last_active,
                        date_txt: dateTxt
                    }
                });
            }
            else {
                // push new inbox row
                const receiverData = data[`details_${receiverId}`] || {
                    id: 0,
                    name: '',
                    pic: ''
                };
                toSort.push({
                    app_type: data.app_type,
                    created: data.created,
                    createdAt: data.createdAt,
                    has_pending_order: 0,
                    last_message: '',
                    last_message_date: '',
                    last_sender: currUserID.toString(),
                    last_active: data.last_active,
                    last_sent: data.last_message_date,
                    listing_id: data.listing_id,
                    ...(data.listingDetails
                        ? {
                            listing_name: data.listingDetails.listingName || '',
                            listingDetails: data.listingDetails
                        } : {
                        listing_name: '',
                        listingDetails: {
                            listingHostId: -1,
                            listingId: -1,
                            listingName: ''
                        }
                    }),
                    message_count: 1,
                    messages: [redactMsg],
                    receiver_id: receiverData.id || Number(receiverId),
                    ...{
                        receiver_name: receiverData.name || '',
                        receiver_pic: receiverData.pic || '',
                    },
                    unread: data[`unread_${currUserID}`],
                    users: data.users,
                    date_txt: dateTxt,
                    id: convoId
                });
            }
            newIds.push(convoId);
        });
        /**
         * Sort inbox according to last_sent
         */
        for (const key in inboxCopy) {
            if (newIds.indexOf(key) > -1)
                continue;
            toSort.push({ ...inboxCopy[key] });
        }
        const inboxSorted = {};
        toSort.sort((a, b) => {
            return (new Date(b.last_sent).getTime() -
                new Date(a.last_sent).getTime());
        });
        toSort.forEach(item => {
            inboxSorted[item.id] = item;
        });
        // save data
        inbox.value = inboxSorted;
        all.value = allConvo;
    }
    /**
     * Replace chatroom data in inbox using
     * chatroom id
     * @param data single chatroom data
     */
    function updateInbox(data) {
        inbox.value = {
            ...inbox.value,
            ...data
        };
    }
    // end of inbox
    const maxed = ref(false);
    /**
     * If user reaches the end of the inbox page
     * @param boo boolean
     */
    function setMax(boo) {
        maxed.value = boo;
    }
    // query
    const queryApp = ref('getspaces');
    /**
     * App mode to be queried
     * @param app app mode for chat room
     */
    function setQueryApp(app) {
        queryApp.value = app;
    }
    // inbox scroll pos
    const scrollPos = ref(0);
    /**
     * Set scroll pos of inbox to be scrolled
     * to when user returns to inbox from chat
     * @param pos scroll position of inbox
     */
    function setPos(pos) {
        scrollPos.value = pos;
    }
    /******
     * Chat room sending and related stuff
     *****/
    const chatLoading = ref(false);
    function toggleChatLoading(boo) {
        chatLoading.value = boo;
    }
    const currPage = ref(null);
    /**
     * Set page of the chat messages (for pagination)
     * @param page current page of chat messages
     */
    function setPage(page) {
        currPage.value = page;
    }
    const initMsg = ref('');
    /**
     * Clear init message
     */
    function clearInit() {
        initMsg.value = '';
    }
    /**
     * Add message to be sent when user
     * enters the chatroom
     * @param msg init message
     */
    function addInitMsg(msg) {
        initMsg.value = msg;
    }
    // sending text msg
    const sending = ref(false);
    /**
     * When user sends a text message,
     * block current user from exiting
     * @param boo boolean
     */
    function updateSending(boo) {
        sending.value = boo;
    }
    // upload files
    const uploading = ref(false);
    /**
     * When user upload files etc, block current user
     * from exiting
     * @param boo boolean
     */
    function updateUploading(boo) {
        uploading.value = boo;
    }
    const enroute = ref(null);
    /**
     * Save path to route to after
     * sending is complete
     * @param data saved path data
     */
    function setRoute(data) {
        enroute.value = data;
    }
    /******
     * Clear all data
     *****/
    function clearAll() {
        all.value = {};
        currPage.value = null;
        inbox.value = {};
        initMsg.value = '';
        scrollPos.value = 0;
        currentConvo.value = {
            id: '',
            receiver_pic: '',
            receiver_name: '',
            listing_name: ''
        };
    }
    return {
        all,
        parseChatRoom,
        addMessagesToList,
        clearConversation,
        currentConvo,
        setCurrentConversation,
        inbox,
        updateInbox,
        setConvo,
        maxed,
        setMax,
        queryApp,
        setQueryApp,
        scrollPos,
        setPos,
        chatLoading,
        toggleChatLoading,
        currPage,
        setPage,
        initMsg,
        clearInit,
        addInitMsg,
        sending,
        updateSending,
        uploading,
        updateUploading,
        enroute,
        setRoute,
        clearAll
    };
});
