import { selectedLocalePlugin } from './selectedLocale';
import date from 'quasar/src/utils/date/date.js';
const { formatDate } = date;
import { dtLocaleIntPlugin } from 'src/plugins/i18n/datetime/datetime';
const dtWithLocaleFormat = () => {
    const { locale } = dtLocaleIntPlugin();
    const { dtLocale } = selectedLocalePlugin();
    function formatDateWithSysLocale(dateVal, format, useDefaultLocale = false) {
        return formatDate(dateVal, format, useDefaultLocale
            ? locale['en-US']
            : dtLocale.value);
    }
    return {
        formatDateWithSysLocale
    };
};
export { dtWithLocaleFormat };
