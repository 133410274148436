import { onMounted, watch } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { filter, tap, map, } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { AppGeneralVariables } from 'src/plugins/general/variables';
import { gtag } from 'src/plugins/google/analytics';
/**
 * Contains standard methods affecting base
 * app style
 */
const StandardScriptPlugin = () => {
    const $q = useQuasar();
    /**
     * Load additional conditional scripts
     * @returns void
     */
    function loadScripts() {
        if (typeof document === 'undefined')
            return;
        if ((process.env.prodType !== 'prod') || $q.platform.is.capacitor)
            return;
        const hotjarScript = document.createElement('script');
        hotjarScript.type = 'text/javascript';
        hotjarScript.setAttribute('defer', '');
        hotjarScript.setAttribute('nonce', window.nonce);
        const hotjarCode = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${process.env.hotjar_id},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
        try {
            hotjarScript.appendChild(document.createTextNode(hotjarCode));
            document.head.appendChild(hotjarScript);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }
        catch (e) {
            hotjarScript.text = hotjarCode;
            document.head.appendChild(hotjarScript);
        }
    }
    const { subject: appMountedWatcher$ } = rxInitSubjectCallback();
    useObservable(appMountedWatcher$.pipe(map(({ newValue }) => newValue), filter((v) => v), tap(() => {
        gtag('config', process.env.uaMeasurementId, {
            transport_url: process.env.gtmUrl,
            first_party_collection: true,
            custom_map: {
                dimension5: 'user_id',
                metric1: 'chat_click',
                metric2: 'recurring',
                metric3: 'landmarks',
                metric4: 'bargain',
            },
        });
    })));
    const { appMounted } = AppGeneralVariables();
    watch(appMounted, (newValue, oldValue) => {
        appMountedWatcher$.next({ newValue, oldValue });
    });
    onMounted(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('load', () => {
                loadScripts();
            }, { passive: true, once: true });
        }
    });
};
export { StandardScriptPlugin };
