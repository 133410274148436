/**
 * List of data to be persisted in localstorage or cookies
 */
export const persistedStateList = {
    account: {
        local: {
            temp: [],
            perm: ['firebaseToken', 'hostOrder', 'custBookings'],
        },
        cookies: {
            temp: ['refreshingUserCount'],
            perm: ['loggedIn'],
        },
    },
    app: {
        local: {
            temp: ['appTab', 'searchProperty', 'searchVenue', 'savedTab'],
            perm: [],
        },
        cookies: {
            temp: [],
            perm: [],
        },
    },
    central: {
        local: {
            temp: ['hostRegistration', 'preloginPath', 'searchPageVisited'],
            perm: ['mode'],
        },
        cookies: {
            temp: ['savedPath'],
            perm: ['locale'],
        },
    },
    conversations: {
        local: {
            temp: ['currentConvo'],
            perm: [],
        },
        cookies: {
            temp: [],
            perm: [],
        },
    },
    flex: {
        local: {
            temp: [
                'appliedOffer',
                'billing',
                'bookingDialog',
                'misc',
                'redeemedPoints',
                'selectedAddons',
                'selectedSku',
            ],
            perm: ['listingDefaultOpen', 'listingDisableSection'],
        },
        cookies: {
            temp: [],
            perm: [],
        },
    },
    property: {
        local: {
            temp: ['misc', 'pendingDialog'],
            perm: [],
        },
        cookies: {
            temp: [],
            perm: [],
        },
    },
    searches: {
        local: {
            temp: [
                'filters',
                'ordering',
                'page',
                'savedGoogleInput',
                'savedGoogleInputMap',
                'searchPageView',
                'sideMap',
                'map',
                'initMapCountry',
                'mapMoved'
            ],
            perm: [],
        },
        cookies: {
            temp: [],
            perm: [],
        },
    },
    view: {
        local: {
            temp: ['bookingRequest', 'recurringRequest'],
            perm: ['permaFAB', 'showSubscriber', 'verifierLastShown'],
        },
        cookies: {
            temp: ['chatTab'],
            perm: [],
        },
    },
};
