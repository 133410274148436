import Platform from 'quasar/src/plugins/platform/Platform.js';
import { defineBoot } from '#q-app/wrappers';
import { useCentralStore } from 'src/stores/central';
export default defineBoot(({ ssrContext }) => {
    const centralStore = useCentralStore();
    const platform = process.env.SERVER
        ? Platform.parseSSR(ssrContext || {})
        : Platform;
    const platformIs = platform.is;
    centralStore.setPlatformInfo({
        android: platformIs.android || false,
        ios: platformIs.ios || false,
        mobile: platformIs.mobile || false,
        desktop: platformIs.desktop || false,
        capacitor: platformIs.capacitor || false,
        isApp: platformIs.capacitor || false
    });
});
