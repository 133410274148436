import { computed } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { useAccountStore } from 'src/stores/account';
import { useCentralStore } from 'src/stores/central';
import { StandardAlertsPlugin } from 'src/plugins/alert/alertPlugin';
import { AxiosDataParser } from 'src/plugins/axios/axiosParser';
import { tteCalculator } from 'src/features/account/methods/tteCalculator';
import { useRoute } from 'vue-router';
/**
 * standard way to call backend using axios
 * with the check if user is authenticated
 * @returns wrapped axios caller with time to expire
 */
const axiosCallerPlugin = () => {
    const $q = useQuasar();
    const vueRoute = useRoute();
    const { successAlert, errorAlert } = StandardAlertsPlugin();
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const user = computed(() => accountStore.user);
    const userMisc = computed(() => accountStore.userMisc);
    async function axiosCall(data) {
        const params = data.params || {};
        const axiosLib = new AxiosDataParser({
            ...data,
            params: {
                ...params,
                ...(params.country_code
                    ? {}
                    : {
                        country_code: (vueRoute.params.country || '').toString() || 'gen',
                    }),
            },
        }, {
            timeToExpire: tteCalculator(user.value) || 0,
            customAuthHeader: null,
            userMisc: userMisc.value,
        });
        const { loading, loadingMessage } = axiosLib.axiosLoading();
        if (loading) {
            $q.loading.show({
                message: loadingMessage,
                spinnerSize: 250,
                spinnerColor: 'accent',
            });
        }
        if (data.url === 'user_auth/auth_init_wif_auth') {
            tteCalculator(user.value);
        }
        // if user is offline
        if (!centralStore.online) {
            errorAlert('There are some errors with your internet connection. Please try again later');
            $q.loading.hide();
            return {
                success: false,
                error: {
                    message: 'Is offline',
                    code: 0,
                    statusText: '',
                    reason: '',
                },
                data: null,
            };
        }
        const axiosResponse = await axiosLib.backendCall();
        const axiosError = axiosResponse.error;
        if (axiosError !== null) {
            if (([401, 403].indexOf(axiosError.code) > -1 ||
                (typeof axiosError.message === 'string' &&
                    axiosError.message === 'User is not authenticated')) &&
                data.state !== 'init') {
                // check if user is authenticated. refresh token if necessary
                centralStore.setRequeryInit();
            }
            else if (axiosError.code === 500) {
                errorAlert('Server error. Please contact the customer service if this persists');
            }
            else if (axiosError.code === 408) {
                errorAlert('Request timeout. Please try again later');
            }
            else if (axiosError.code === 404) {
                errorAlert('Not found');
            }
            else if (typeof axiosError.reason === 'string') {
                errorAlert(axiosError.reason);
            }
        }
        else {
            // show alert on success if its specified
            if (data.alert && data.message) {
                successAlert(data.message);
            }
        }
        if (loading) {
            $q.loading.hide();
        }
        return axiosResponse;
    }
    return {
        axiosCall,
    };
};
export { axiosCallerPlugin };
