// Meta store
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useLoggingStore = defineStore('logging', () => {
    const errors = ref([]);
    /**
     * Centralized error handling. To add logging to sentry later
     * @param errorData any data
     */
    function errorHandler(errorData) {
        errors.value = [...errors.value, errorData];
    }
    return {
        errors,
        errorHandler,
    };
});
