import commonMsgsZh from 'src/i18n/zh/common/common.json';
import listingMsgsZh from 'src/i18n/zh/listing/common.json';
import flexMsgsZh from 'src/i18n/zh/listing/flex.json';
import propertyMsgsZh from 'src/i18n/zh/listing/properties.json';
import searchMsgsZh from 'src/i18n/zh/search/search.json';
export default {
    ...commonMsgsZh,
    flex: flexMsgsZh,
    property: propertyMsgsZh,
    listing: listingMsgsZh,
    search: searchMsgsZh
};
