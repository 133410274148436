import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { ref, onMounted } from 'vue';
import { useLoggingStore } from 'src/stores/logging';
import { of, timer, throwError } from 'rxjs';
import { catchError, filter, retry, tap, switchMap } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { v4 as uuidv4 } from 'uuid';
/**
 * Capacitor Google Login functions that uses functions from a custom
 * package so as to allow users to login using google login on capacitor
 *
 * Its using a custom package because the main branch of capacitor google login
 * does not support custom scopes on a function level
 *
 * @param scopes custom scopes for the login
 * @param offline if the offline access to the user's account is required
 * @returns
 */
const CapacitorGoogleLoginFunction = (scopes = ['profile', 'email'], offline = false) => {
    const $q = useQuasar();
    const loggingStore = useLoggingStore();
    const sessionIdentifier = ref(uuidv4());
    const CapacitorGoogleLoggedIn = ref(null);
    /**
     * Function to parse the JWT ID token into a JSON object
     * with user details
     *
     * @param token JWT ID token
     */
    /*
    function parseJwt (token: string) {
      const base64Url = token.split('.')[1]!!;
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload) as {
        email: string;
              
        name: string;
        family_name: string;
        given_name: string;
        picture: string;
      };
    }
    */
    /**
     * Function to refresh the user details. Used
     * when user was logged in before and the custom data
     * is still saved somewhere in the app
     */
    const { subject: refreshUser$ } = rxInitSubjectCallback();
    useObservable(refreshUser$.pipe(filter(() => $q.platform.is.capacitor || false), switchMap(() => {
        return import('@capgo/capacitor-social-login');
    }), switchMap(({ SocialLogin }) => {
        return of('').pipe(switchMap(() => {
            return SocialLogin.refresh({
                provider: 'google',
                options: {
                    scopes: scopes,
                    forceRefreshToken: true,
                    nonce: window.nonce
                }
            });
        }));
    })));
    /**
     * Function to login using capacitor google login
     */
    const { subject: signInRequest$ } = rxInitSubjectCallback();
    useObservable(signInRequest$.pipe(filter(() => $q.platform.is.capacitor || false), switchMap(() => {
        return import('@capgo/capacitor-social-login');
    }), switchMap(({ SocialLogin }) => {
        return of('').pipe(switchMap(() => {
            return SocialLogin.login({
                provider: 'google',
                options: {
                    scopes: scopes,
                    forceRefreshToken: true,
                    nonce: window.nonce
                }
            });
        }), retry({
            count: 2,
            delay: (error, retryCount) => {
                /**
                 * Retry when something went wrong
                 */
                if (error.errorMessage &&
                    error.errorMessage.includes('something went wrong')) {
                    return throwError(() => error);
                }
                if (error.errorMessage && (error.errorMessage.includes('cancel') ||
                    error.errorMessage.includes('IDSignIn error -8'))) {
                    // somehow need to use refresh user
                    // flow when such an error code is returned.
                    // user was logged in before
                    if (error.errorMessage.includes('IDSignIn error -8')) {
                        refreshUser$.next('');
                    }
                    return throwError(() => error);
                }
                return timer(800 * retryCount);
            }
        }));
    }), filter((v) => v !== null), tap(({ result }) => {
        const loginData = result;
        CapacitorGoogleLoggedIn.value = {
            id: loginData.profile?.id || "",
            email: loginData.profile?.email || "",
            name: loginData.profile?.name || "",
            familyName: loginData.profile?.familyName || "",
            givenName: loginData.profile?.givenName || "",
            imageUrl: loginData.profile?.imageUrl || "",
            serverAuthCode: loginData.serverAuthCode || "",
            authentication: {
                idToken: loginData.idToken || "",
                accessToken: loginData.accessToken?.token || "",
                refreshToken: loginData.accessToken?.refreshToken || ""
            }
        };
    })));
    function capacitorGoogleLogin() {
        signInRequest$.next('');
    }
    /**
     * Logout from google account
     */
    const { subject: signOutRequest$ } = rxInitSubjectCallback();
    const CapacitorGoogleLoggedOut = ref(false);
    useObservable(signOutRequest$.pipe(filter(() => $q.platform.is.capacitor || false), switchMap(() => {
        return import('@capgo/capacitor-social-login');
    }), switchMap(({ SocialLogin }) => {
        return of('').pipe(switchMap(() => {
            return SocialLogin.logout({
                provider: 'google'
            });
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of(null);
        }));
    }), tap(() => {
        CapacitorGoogleLoggedOut.value = true;
    })));
    function capacitorGooglelogout() {
        signOutRequest$.next('');
    }
    /**
     * Initialize google login params and scopes
     */
    const { subject: mountedWatch$ } = rxInitSubjectCallback();
    useObservable(mountedWatch$.pipe(filter(() => $q.platform.is.capacitor || false), switchMap(() => {
        return import('@capgo/capacitor-social-login');
    }), switchMap(({ SocialLogin }) => {
        return of('').pipe(switchMap(() => {
            return SocialLogin.initialize({
                google: {
                    iOSClientId: process.env.googleSignInParamsIos,
                    webClientId: process.env.googleSignInParamsAndroid,
                    iOSServerClientId: process.env.googleSignInParams,
                    ...(offline ? {
                        mode: 'offline'
                    } : {})
                }
            });
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of(null);
        }));
    })));
    onMounted(() => {
        mountedWatch$.next('');
    });
    return {
        sessionIdentifier,
        capacitorGoogleLogin,
        CapacitorGoogleLoggedIn,
        capacitorGooglelogout,
        CapacitorGoogleLoggedOut,
    };
};
export { CapacitorGoogleLoginFunction };
