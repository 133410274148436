import { computed, reactive, ref, watch } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { useRouter, useRoute } from 'vue-router';
import { useAccountStore } from 'src/stores/account';
import { useAppModeStore } from 'src/stores/appMode';
import { useConvoStore } from 'src/stores/conversations';
import { useFlexStore } from 'src/stores/flex';
import { useLoggingStore } from 'src/stores/logging';
import { of, from, merge } from 'rxjs';
import { debounceTime, catchError, map, switchMap, filter, tap, delay, mergeMap, } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { Preferences } from '@capacitor/preferences';
import { RefreshPagePlugin } from 'src/plugins/general/refreshPage';
import { CapacitorGoogleLoginFunction } from 'src/plugins/google/CapacitorGoogleLogin';
import { axiosCallerPlugin } from 'src/plugins/axios/componentAxiosCallerPlugin';
const LogoutFunctionsPlugin = () => {
    const $q = useQuasar();
    const vueRoute = useRoute();
    const vueRouter = useRouter();
    const accountStore = useAccountStore();
    const appModeStore = useAppModeStore();
    const convoStore = useConvoStore();
    const flexStore = useFlexStore();
    const loggingStore = useLoggingStore();
    const { refreshPage } = RefreshPagePlugin();
    const { capacitorGooglelogout, CapacitorGoogleLoggedOut } = CapacitorGoogleLoginFunction();
    const { subject: backendLogout$ } = rxInitSubjectCallback();
    const { subject: clearIDB$ } = rxInitSubjectCallback();
    const { subject: clearStorage$ } = rxInitSubjectCallback();
    const { subject: firebaseLogout$ } = rxInitSubjectCallback();
    const { subject: firebaseAuthLogout$ } = rxInitSubjectCallback();
    const { subject: forceRefreshCountdown$ } = rxInitSubjectCallback();
    const { subject: loggingOutWatcher$ } = rxInitSubjectCallback();
    const loggingOut = computed(() => accountStore.loggingOut);
    const user = computed(() => accountStore.user);
    const cleared = reactive({
        capGoogle: true,
        backend: false,
        firebase: false,
        idb: false,
        storage: false,
        store: false
    });
    if ($q.platform.is.capacitor) {
        cleared.capGoogle = false;
    }
    useObservable(clearStorage$.pipe(tap(() => {
        // clear cookies and storage on log out
        // make exception to certain keys
        const cookieExemptKeys = ['bId'];
        void flexStore.clearAll();
        appModeStore.toggleSearchVenue({
            id: null
        });
        const allCookies = $q.cookies.getAll();
        for (const key in allCookies) {
            if (cookieExemptKeys.indexOf(key) > -1)
                continue;
            $q.cookies.remove(key);
        }
    }), filter(() => {
        if (process.env.SERVER) {
            cleared.storage = true;
            return false;
        }
        return true;
    }), tap(() => {
        const sessionExemptKeys = ['tabId'];
        const allSession = ($q.sessionStorage.getAll() || {});
        for (const key in allSession) {
            if (sessionExemptKeys.indexOf(key) > -1)
                continue;
            $q.sessionStorage.remove(key);
        }
    }), tap(() => {
        const localExemptKeys = ['tabIdList', 'current_token', 'latestTabId'];
        const allLocal = ($q.localStorage.getAll() || {});
        for (const key in allLocal) {
            if (localExemptKeys.indexOf(key) > -1)
                continue;
            $q.localStorage.remove(key);
        }
    }), switchMap(() => {
        return Preferences.clear();
    }), tap(() => {
        cleared.storage = true;
    })));
    function clearStore() {
        accountStore.setUnreadChat(0);
        accountStore.setUnreadNotif(0);
        accountStore.setFlexCredits([]);
        void convoStore.clearAll();
        cleared.store = true;
    }
    const { axiosCall } = axiosCallerPlugin();
    useObservable(backendLogout$.pipe(switchMap(() => {
        return of('').pipe(switchMap(() => {
            return axiosCall({
                url: 'user_auth/logout',
                urlType: 'common',
                type: 'create',
                alert: false,
                loading: false,
                data: {
                    is_app: $q.platform.is.capacitor || false
                }
            });
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of({ success: true });
        }), tap(() => {
            cleared.backend = true;
        }));
    })));
    const firebaseAuthLoggingOut = ref(false);
    useObservable(merge(
    // continue with firebase logout after 3 seconds.
    // incase the rtd logout fails
    firebaseLogout$.pipe(debounceTime(1500)), firebaseAuthLogout$).pipe(filter(() => !firebaseAuthLoggingOut.value), tap(() => firebaseAuthLoggingOut.value = true), switchMap(() => {
        return import(
        /* webpackChunkName: "firebaseMethod" */
        'src/_helpers/firebaseMethods');
    }), map(({ default: firebaseMethods }) => firebaseMethods), switchMap((firebaseMethods) => {
        return firebaseMethods();
    }), switchMap(({ auth }) => {
        return of('').pipe(switchMap(() => {
            return import(
            /* webpackChunkName: "firebaseAuth" */
            'firebase/auth');
        }), switchMap(({ signOut }) => {
            return signOut(auth);
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of({ success: true });
        }), tap(() => {
            cleared.firebase = true;
        }));
    })));
    useObservable(firebaseLogout$.pipe(switchMap(() => {
        return import(
        /* webpackChunkName: "firebaseRTD" */
        'firebase/database');
    }), switchMap(({ ref: rtdRef, set, serverTimestamp, getDatabase }) => {
        return of('').pipe(switchMap(() => {
            // set online offline on realtime db
            if (user.value && user.value.user_pk) {
                const db = getDatabase();
                return set(rtdRef(db, `/status/${user.value.user_pk}`), {
                    state: 'offline',
                    last_changed: serverTimestamp(),
                });
            }
            return of({ success: true });
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of({ success: true });
        }), tap(() => {
            firebaseAuthLogout$.next('');
        }));
    })));
    // force refresh
    useObservable(forceRefreshCountdown$.pipe(tap(() => {
        $q.loading.show({
            message: 'Logging Out......',
            spinnerSize: 250,
            spinnerColor: 'accent',
        });
    }), 
    // force refresh after 5 seconds
    debounceTime(5000), tap(() => {
        if (typeof vueRoute.name === 'string' &&
            ['Search', 'Reset Password'].indexOf(vueRoute.name) === -1) {
            void vueRouter.push({ name: 'Search' });
        }
        refreshPage(false, true);
    }), 
    // need to scroll to top for ios capacitor
    filter(() => ($q.platform.is.capacitor === true) && ($q.platform.is.ios === true)), delay(700), tap(() => {
        window?.scrollTo(0, 0);
    })));
    useObservable(clearIDB$.pipe(debounceTime(200), switchMap(() => {
        const storeList = [
            'consolidated-flex-availability',
            'venue',
            'prop-listing'
        ];
        let clearedCount = 0;
        return of('').pipe(switchMap(() => {
            return import('idb');
        }), switchMap(({ openDB }) => {
            return openDB('get_tech');
        }), mergeMap((db) => {
            return from(storeList).pipe(switchMap((v) => {
                return of('').pipe(tap(() => {
                    void db.clear(v);
                }), tap(() => {
                    clearedCount += 1;
                }));
            }));
        }), tap(() => {
            cleared.idb = clearedCount >= storeList.length;
        }));
    })));
    useObservable(loggingOutWatcher$.pipe(filter((v) => {
        return v.oldValue !== v.newValue;
    }), map(({ newValue }) => newValue), filter((v) => v !== false), tap(() => {
        backendLogout$.next('');
        capacitorGooglelogout();
        firebaseLogout$.next('');
        forceRefreshCountdown$.next('');
    }), debounceTime(200), tap(() => {
        clearStore();
        clearStorage$.next('');
        clearIDB$.next('');
    })));
    watch(CapacitorGoogleLoggedOut, (newValue) => {
        if (newValue)
            cleared.capGoogle = true;
    });
    watch(loggingOut, (newValue, oldValue) => {
        loggingOutWatcher$.next({ newValue, oldValue });
    });
    watch(() => ({ ...cleared }), (newValue) => {
        let allCleared = true;
        for (const key in newValue) {
            const typedKey = key;
            if (!newValue[typedKey]) {
                allCleared = false;
                break;
            }
        }
        if (!allCleared)
            return;
        accountStore.loggedOut();
        refreshPage(true, true);
    });
};
export { LogoutFunctionsPlugin };
