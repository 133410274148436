import commonMsgsId from 'src/i18n/id/common/common.json';
import listingMsgsId from 'src/i18n/id/listing/common.json';
import flexMsgsId from 'src/i18n/id/listing/flex.json';
import propertyMsgsId from 'src/i18n/id/listing/properties.json';
import searchMsgsId from 'src/i18n/id/search/search.json';
export default {
    ...commonMsgsId,
    flex: flexMsgsId,
    property: propertyMsgsId,
    listing: listingMsgsId,
    search: searchMsgsId
};
