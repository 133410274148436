// Property page store
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLoggingStore } from './logging';
export const usePropertyStore = defineStore('property', () => {
    const loggingStore = useLoggingStore();
    /*******
     * Viewing
     ******/
    const booking = ref([{
            date: '',
            from_time: '',
            to_time: ''
        }]);
    /**
     * Set proposed viewing timing
     * @param bookingData viewing timings
     */
    function setBooking(bookingData) {
        booking.value = bookingData;
    }
    /**
     * Clear viewing timing
     */
    function clearTimeslots() {
        booking.value = [{
                date: '',
                from_time: '',
                to_time: ''
            }];
    }
    // listing
    const listing = ref(null);
    /**
     * save listing to store and IDB for easy access
     * @param listingObj listing data
     * @param dbInitialized idb initialized
     */
    async function saveListing(listingObj, dbInitialized) {
        if (!('disableDbSave' in listingObj)) {
            listingObj.disableDbSave = false;
        }
        if ('id' in listingObj) {
            listingObj.id = Number(listingObj.id);
        }
        listing.value = listingObj;
        if (process.env.SERVER || !dbInitialized)
            return;
        if (listingObj.disableDbSave && ((listingObj.revision || 0) < 2))
            return;
        const { openDB } = await import('idb');
        const db = await openDB('get_tech');
        const tx = db.transaction('prop-listing', 'readwrite');
        try {
            await Promise.all([
                tx.store.put({
                    ...listingObj,
                    key: listingObj.id,
                    last_updated: new Date()
                }),
                tx.done
            ]);
        }
        catch (error) {
            loggingStore.errorHandler(error);
        }
    }
    /**
     * Toggle if listing is favourited
     * @param favId if listing is favourited
     */
    function setListingFav(favBool) {
        const listingCopy = { ...listing.value };
        listingCopy.is_fav = favBool;
        // @ts-expect-error ignore
        listing.value = listingCopy;
    }
    /**
     * Clear listing
     */
    function clearListing() {
        listing.value = null;
    }
    // misc
    const misc = ref({
        viewing_remark: '',
        using_for: ''
    });
    /**
     * Set booking details for viewing
     * @param miscData viewing details
     */
    function setMisc(miscData) {
        misc.value = {
            ...misc.value,
            ...miscData
        };
    }
    /**
     * Clear viewing details
     */
    function clearMisc() {
        misc.value = {
            viewing_remark: '',
            using_for: ''
        };
    }
    const phoneDetails = ref({
        order_for_phone_no_area_code: null,
        order_for_phone_no_country_code: null,
        order_for_phone_no: null,
    });
    /**
     * Set phone details of the viewer
     * @param data phone details
     */
    function setPhoneDetails(data) {
        phoneDetails.value = data;
    }
    /*******
     * Listing Page
     ******/
    const bookingDialog = ref(false);
    /**
     * Show popup to create viewing on mobile
     * @param boo boolean
     */
    function toggleBookingDialog(boo) {
        bookingDialog.value = boo;
    }
    // view
    const listingDefaultOpen = ref([]);
    /**
     * Set sections that are opened by default
     * @param data sections that are open by default
     */
    function setListingDefaultOpen(data) {
        listingDefaultOpen.value = data;
    }
    // sections that are not expandable item
    const listingDisableSection = ref([]);
    /**
     * Set sections that are not expandable item
     * @param data sections that are not not expandable item
     */
    function setListingDisableSection(data) {
        listingDisableSection.value = data;
    }
    const pendingDialog = ref(false);
    /**
     * If user is logged in and user wants to add viewing
     * save here so that the user can add viewing after login
     * @param boo boolean
     */
    function togglePendingBookingDialog(boo) {
        pendingDialog.value = boo;
    }
    return {
        booking,
        setBooking,
        clearTimeslots,
        misc,
        setMisc,
        clearMisc,
        phoneDetails,
        setPhoneDetails,
        listing,
        saveListing,
        clearListing,
        setListingFav,
        bookingDialog,
        toggleBookingDialog,
        listingDefaultOpen,
        setListingDefaultOpen,
        listingDisableSection,
        setListingDisableSection,
        pendingDialog,
        togglePendingBookingDialog
    };
});
