import commonMsgsEn from 'src/i18n/en-us/common/common.json';
import listingMsgsEn from 'src/i18n/en-us/listing/common.json';
import flexMsgsEn from 'src/i18n/en-us/listing/flex.json';
import propertyMsgsEn from 'src/i18n/en-us/listing/properties.json';
import searchMsgsEn from 'src/i18n/en-us/search/search.json';
export default {
    ...commonMsgsEn,
    flex: flexMsgsEn,
    property: propertyMsgsEn,
    listing: listingMsgsEn,
    search: searchMsgsEn
};
