// Store related to view decisions
// including dialog, snackbar, etc.
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useViewStore = defineStore('view', () => {
    const bookingRequest = ref(false);
    /**
     * When user wants to make a booiing request but is
     * not logged in yet. Redirect the user to confirmation
     * page when user is logged in
     * @param boo boolean
     */
    function toggleBookingRequest(boo) {
        bookingRequest.value = boo;
    }
    const recurringRequest = ref(false);
    /**
     * When user wants to make a booiing request but is
     * not logged in yet. Redirect the user to confirmation
     * page when user is logged in
     * @param boo boolean
     */
    function toggleRecurringRequest(boo) {
        recurringRequest.value = boo;
    }
    /*****
     * Show calendar popup
     * ****/
    const showCalendar = ref(false);
    function toggleShowCalendar(boo) {
        showCalendar.value = boo;
    }
    /******
     * Chat and notification
     ******/
    const chatTab = ref('inbox');
    /**
     * change tab of the chat page
     * @param tab 'chat' | 'inbox'
     */
    function toggleChatTab(tab) {
        chatTab.value = tab;
    }
    const inboxMenu = ref(false);
    /**
     * Show inbox menu on desktop
     * @param boo boolean
     */
    function toggleInboxMenu(boo) {
        inboxMenu.value = boo;
    }
    const notificationMenu = ref(false);
    /**
     * Show notification menu on desktop
     * @param boo boolean
     */
    function toggleNotificationMenu(boo) {
        notificationMenu.value = boo;
    }
    /******
     * Dialog generic
     ******/
    const dialogOpened = ref([]);
    const forceCloseDialog = ref(false);
    /**
     * remove the dialog from the list of opened dialog
     * @param dialogId uuidv4 of the dialog
     */
    function closeDialog(dialogId) {
        const dialogIdIndex = dialogOpened.value.indexOf(dialogId);
        if (dialogIdIndex === -1)
            return;
        const dialogOpenCopy = [...dialogOpened.value];
        dialogOpenCopy.splice(dialogIdIndex, 1);
        dialogOpened.value = dialogOpenCopy;
    }
    /**
     * Add dialog to the list of opened dialogs.
     * to close dialog when user clicks on back on android phones
     * @param dialogId uuidv4 of the dialog
     */
    function openDialog(dialogId) {
        const dialogIdIndex = dialogOpened.value.indexOf(dialogId);
        if (dialogIdIndex > -1)
            return;
        const dialogOpenCopy = [...dialogOpened.value];
        dialogOpenCopy.push(dialogId);
        dialogOpened.value = dialogOpenCopy;
    }
    /**
     * Force all dialog to close when this is set to true
     * @param boo boolean
     */
    function toggleForceCloseDialog(boo) {
        forceCloseDialog.value = boo;
    }
    /******
     * Drawer
     ******/
    const drawer = ref(false);
    /**
     * Toggle if drawer is shown
     * @param boo boolean
     */
    function toggleDrawer(boo) {
        drawer.value = boo;
    }
    const drawerTab = ref('functions');
    /**
     * Toggle tab of the drawer
     * @param tab 'functions' | 'chat'
     */
    function toggleDrawerTab(tab) {
        drawerTab.value = tab;
    }
    /******
     * Account login related
     ******/
    const login = ref({
        forced: false,
        required: false,
        tab: 'login'
    });
    /**
     * Show the login popup
     * @param jsonData login popup
     */
    function requireLogin(jsonData) {
        login.value = {
            ...{
                forced: false,
                required: false,
                tab: 'login'
            },
            ...jsonData
        };
    }
    const reset = ref(false);
    /**
     * Show password reset popup
     * @param bool boolean
     */
    function toggleReset(bool) {
        reset.value = bool;
    }
    const showVerifier = ref(false);
    const verifierTab = ref('multi');
    const verifierLastShown = ref(null);
    /**
     * Show verifier dialog (enter pin to verify email)
     * @param bool boolean
     */
    function toggleVerifier(bool, force = false, tab = 'multi') {
        if (!force && bool && verifierLastShown.value !== null) {
            // only automatically show verifier once a day
            // unless forced (i.e user clicks on button)
            const lastShown = new Date(verifierLastShown.value);
            const now = new Date();
            const diff = now.getTime() - lastShown.getTime();
            const diffInHours = diff / (1000 * 3600);
            if (diffInHours < 24) {
                return;
            }
        }
        // when dialog is closed, reset tab
        if (!bool) {
            verifierTab.value = 'multi';
        }
        else {
            verifierLastShown.value = new Date().toISOString();
            verifierTab.value = tab;
        }
        showVerifier.value = bool;
    }
    function changeVerifierTab(tab) {
        verifierTab.value = tab;
    }
    /******
     * Misc
     ******/
    const showFooter = ref(true);
    /**
     * App decides when to show the footer (info footer)
     * based on route
     * @param bool boolean
     */
    function toggleShowFooter(bool) {
        showFooter.value = bool;
    }
    const permaFAB = ref(true);
    /**
     * User can choose to hide the floating action btn permanently
     * @param bool boolean
     */
    function togglePermaFAB(bool) {
        permaFAB.value = bool;
    }
    const showFAB = ref(false);
    /**
     * App decides when to show the floating action btn
     * @param bool boolean
     */
    function toggleShowFAB(bool) {
        showFAB.value = bool;
    }
    const showSubscriber = ref(true);
    /**
     * User can toggle hide the subscription sticky
     * @param bool boolean
     */
    function toggleSubscriber(bool) {
        showSubscriber.value = bool;
    }
    const showPageSubscriber = ref(false);
    /**
     * App can toggle the show and hide of subscription sticky
     * @param bool boolean
     */
    function togglePageSubscriber(bool) {
        showPageSubscriber.value = bool;
    }
    const showTimedSubscriber = ref(false);
    /**
     * Only show the subscription sticky after a certain amount of time
     * @param timer number
     */
    function toggleTimedSubscriber(timer) {
        const worker = new Worker(new URL('./workers/timer.js', import.meta.url));
        worker.onmessage = (event) => {
            worker.terminate();
            showTimedSubscriber.value = event.data;
        };
        worker.postMessage(timer);
    }
    return {
        bookingRequest,
        toggleBookingRequest,
        recurringRequest,
        toggleRecurringRequest,
        showCalendar,
        toggleShowCalendar,
        chatTab,
        toggleChatTab,
        inboxMenu,
        toggleInboxMenu,
        notificationMenu,
        toggleNotificationMenu,
        dialogOpened,
        closeDialog,
        openDialog,
        forceCloseDialog,
        toggleForceCloseDialog,
        drawer,
        drawerTab,
        toggleDrawer,
        toggleDrawerTab,
        login,
        requireLogin,
        reset,
        toggleReset,
        showVerifier,
        verifierTab,
        verifierLastShown,
        toggleVerifier,
        changeVerifierTab,
        showFooter,
        toggleShowFooter,
        permaFAB,
        togglePermaFAB,
        showFAB,
        toggleShowFAB,
        showSubscriber,
        toggleSubscriber,
        showPageSubscriber,
        togglePageSubscriber,
        showTimedSubscriber,
        toggleTimedSubscriber
    };
});
