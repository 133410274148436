import { useCentralStore } from "./stores/central";
import { useSearchStore } from "./stores/search";
import { useLoggingStore } from "./stores/logging";
import { useThreadingStore } from "./stores/threading";
import { defineComponent, computed, watch, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
// import cloneDeep from "lodash.clonedeep";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { AppBaseMetaPlugin } from 'src/layouts/main/methods/baseMeta';
import { FlexCentralQueryPlugin } from 'src/features/datetime/backendAvailability/methods/flexCentralQuery';
import { InitializePlugin } from "src/layouts/main/methods/initialize";
import { LogoutFunctionsPlugin } from "src/features/account/methods/logout";
import { RedirectPlugin } from 'src/layouts/main/methods/redirect';
import { MetaPlugin } from "src/plugins/meta/generalMeta";
import { StandardStylePlugin } from 'src/layouts/main/methods/standardStyle';
import { StandardScriptPlugin } from 'src/layouts/main/methods/scripts';
import { AxiosDataParser } from "src/plugins/axios/axiosParser";
import { SsrStoreHydrationPlugin } from "./stores/plugin/localHydration";
import { GoogleAnalyticsTrack } from "src/plugins/google/analytics";
import { AppIndexDB } from "src/layouts/main/methods/appIndexDb";
export default defineComponent({
    name: "App",
    preFetch({ store, currentRoute, redirect }) {
        if (currentRoute.fullPath.includes("service-worker.js"))
            return;
        /*****
         * Custom function for Number types
         *****/
        Number.prototype.round = function (places) {
            const rounded = 
            // @ts-expect-error ignore
            Math.floor((this * Math.pow(10, places + 2)) / 10) /
                Math.pow(10, places + 1);
            // @ts-expect-error ignore
            return +(Math.round(rounded + "e+" + places) + "e-" + places);
        };
        if (process.env.MODE != 'capacitor')
            return;
        const loggingStore = useLoggingStore(store);
        const centralStore = useCentralStore(store);
        const searchStore = useSearchStore(store);
        return new Promise(resolve => {
            const userCountryAxios = new AxiosDataParser({
                url: 'user_auth/user_current_country',
                urlType: 'common',
                type: 'list',
                alert: false,
                loading: true,
                params: {
                    country_code: currentRoute.params.country?.toString() || "gen"
                }
            }, {});
            resolve(userCountryAxios.backendCall());
        }).then((response) => {
            const { data } = response;
            const { user_current_country_detail } = data;
            if (!user_current_country_detail)
                return;
            centralStore.toggleCountry(user_current_country_detail.user_current_country_pk.toString());
            const center = user_current_country_detail.user_current_country_center;
            const zoomVal = user_current_country_detail.user_current_country_zoom;
            searchStore.setMapCenter(center);
            searchStore.setMapZoom(zoomVal);
            if (!searchStore.map ||
                searchStore.initMapCountry != user_current_country_detail.user_current_country_code) {
                searchStore.updateMapCountry(user_current_country_detail.user_current_country_code);
                searchStore.updateMap({
                    zoom: zoomVal,
                    center: center,
                });
            }
            centralStore.toggleTimezone(user_current_country_detail.user_current_country_pytz_timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone);
            if (currentRoute.params.country === 'gen') {
                redirect(currentRoute.fullPath.replaceAll(/\/gen\//g, `/${user_current_country_detail.user_current_country_code.toLowerCase()}/`));
            }
        }).catch((err) => {
            loggingStore.errorHandler(err);
        });
    },
    setup() {
        const $q = useQuasar();
        // initialize stores
        SsrStoreHydrationPlugin();
        const centralStore = useCentralStore();
        const threadingStore = useThreadingStore();
        // initialize meta info
        MetaPlugin();
        // after which, initialize plugins
        AppBaseMetaPlugin();
        LogoutFunctionsPlugin();
        RedirectPlugin();
        StandardScriptPlugin();
        StandardStylePlugin();
        FlexCentralQueryPlugin();
        /*****
         * Initialize user data
         *****/
        const { initializeApp } = InitializePlugin();
        /*****
         * If app is online, push the threading runs to continue
         *****/
        const isOnline = computed(() => centralStore.online);
        watch(isOnline, (newValue) => {
            if (!newValue)
                return;
            threadingStore.nextInQueue();
        });
        const { openIndexDB } = AppIndexDB();
        const { userTagging } = GoogleAnalyticsTrack();
        onMounted(() => {
            userTagging();
            openIndexDB();
            initializeApp(false);
            // set pool size for threading
            if (typeof navigator !== "undefined") {
                if (typeof navigator.hardwareConcurrency !== "undefined") {
                    threadingStore.setPoolSize(navigator.hardwareConcurrency * 2);
                }
            }
            // set unique identifier for this browser
            // for tagging
            const bId = $q.platform.is.capacitor
                ? $q.localStorage.getItem("bId")
                : $q.cookies.get("bId");
            if (!bId) {
                if ($q.platform.is.capacitor) {
                    void $q.localStorage.set("bId", uuidv4());
                }
                else {
                    void $q.cookies.set("bId", uuidv4(), {
                        expires: "99999d",
                        path: "/",
                        secure: false,
                    });
                }
            }
            // after all are done
            centralStore.toggleMounted(true);
        });
    },
});
