export const actionMapper = {
    account: {
        loggedOut: {
            states: ['firebaseToken', 'loggedIn', 'user', 'userMisc', 'refreshingUserCount'],
            type: 'delete',
        },
        login: {
            states: ['firebaseToken', 'loggedIn', 'user', 'userMisc'],
            type: 'set',
        },
        refresh: {
            states: ['firebaseToken', 'loggedIn', 'user', 'userMisc', 'refreshingUserCount'],
            type: 'set',
        },
        otherTabLogout: {
            states: ['userMisc', 'user', 'firebaseToken'],
            type: 'delete',
        },
        setBookings: {
            states: ['custBookings'],
            type: 'set',
        },
        setOrder: {
            states: ['hostOrder'],
            type: 'set',
        },
        updateUser: {
            states: ['userMisc', 'user'],
            type: 'set',
        },
    },
    app: {
        toggleAppTab: {
            states: ['appTab'],
            type: 'set',
        },
        toggleSavedTab: {
            states: ['savedTab'],
            type: 'set',
        },
        toggleSearchProperty: {
            states: ['searchProperty'],
            type: 'set',
        },
        toggleSearchVenue: {
            states: ['searchVenue'],
            type: 'set',
        },
    },
    central: {
        setLocale: {
            states: ['locale'],
            type: 'set',
        },
        setMode: {
            states: ['mode'],
            type: 'set',
        },
        setPreLoginPath: {
            states: ['preloginPath'],
            type: 'set',
        },
        setSavedPath: {
            states: ['savedPath'],
            type: 'set',
        },
        toggleHostRegistration: {
            states: ['hostRegistration'],
            type: 'set',
        },
        toggleSearchPageVisited: {
            states: ['searchPageVisited'],
            type: 'set',
        },
    },
    conversations: {
        setCurrentConversation: {
            states: ['currentConvo'],
            type: 'set',
        },
        clearAll: {
            states: ['currentConvo'],
            type: 'delete',
        },
    },
    flex: {
        clearAll: {
            states: [
                'appliedOffer',
                'billing',
                'booking',
                'misc',
                'redeemedPoints',
                'selectedAddons',
                'selectedSku',
            ],
            type: 'delete',
        },
        clearBilling: {
            states: ['billing'],
            type: 'delete',
        },
        clearListing: {
            states: ['listing'],
            type: 'delete',
        },
        clearMisc: {
            states: ['misc'],
            type: 'delete',
        },
        clearTimeslots: {
            states: ['booking'],
            type: 'delete',
        },
        removeAddons: {
            states: ['selectedAddons'],
            type: 'set',
        },
        saveAddons: {
            states: ['selectedAddons'],
            type: 'set',
        },
        saveListing: {
            states: ['listing'],
            type: 'set',
        },
        setBilling: {
            states: ['billing'],
            type: 'set',
        },
        setBooking: {
            states: ['booking'],
            type: 'set',
        },
        setMisc: {
            states: ['misc'],
            type: 'set',
        },
        setPrice: {
            states: ['appliedOffer'],
            type: 'set',
        },
        setRedeemPoints: {
            states: ['redeemedPoints'],
            type: 'set',
        },
        setSku: {
            states: ['selectedSku'],
            type: 'set',
        },
        toggleBookingDialog: {
            states: ['bookingDialog'],
            type: 'set',
        },
    },
    host: {
        resetFlex: {
            states: ['flexCreate'],
            type: 'delete',
        },
        updateFlex: {
            states: ['flexCreate'],
            type: 'set',
        },
        resetProperty: {
            states: ['propertyCreate'],
            type: 'delete',
        },
        updateProperty: {
            states: ['propertyCreate'],
            type: 'set',
        },
    },
    property: {
        clearListing: {
            states: ['listing'],
            type: 'delete',
        },
        clearMisc: {
            states: ['misc'],
            type: 'delete',
        },
        clearTimeslots: {
            states: ['booking'],
            type: 'delete',
        },
        saveListing: {
            states: ['listing'],
            type: 'set',
        },
        setBooking: {
            states: ['booking'],
            type: 'set',
        },
        setMisc: {
            states: ['misc'],
            type: 'set',
        },
        togglePendingBookingDialog: {
            states: ['pendingDialog'],
            type: 'set',
        },
    },
    searches: {
        bulkChangeFilters: {
            states: ['filters'],
            type: 'set',
        },
        clearFilters: {
            states: ['filters', 'ordering', 'page'],
            type: 'delete',
        },
        replaceFilters: {
            states: ['filters'],
            type: 'set',
        },
        removeFilters: {
            states: ['filters'],
            type: 'set',
        },
        resetMap: {
            states: ['map', 'mapMoved', 'savedGoogleInput', 'savedGoogleInputMap'],
            type: 'delete',
        },
        setGoogleInput: {
            states: ['savedGoogleInput'],
            type: 'set',
        },
        setGoogleInputMap: {
            states: ['savedGoogleInputMap'],
            type: 'set',
        },
        setMapMovement: {
            states: ['mapMoved'],
            type: 'set',
        },
        setOrdering: {
            states: ['ordering'],
            type: 'set',
        },
        setPage: {
            states: ['page'],
            type: 'set',
        },
        setSearchScrollPos: {
            states: ['searchScrollPos'],
            type: 'set',
        },
        setSearchView: {
            states: ['searchPageView'],
            type: 'set',
        },
        toggleSideMap: {
            states: ['sideMap'],
            type: 'set',
        },
        updateMap: {
            states: ['map'],
            type: 'set',
        },
        updateMapCountry: {
            states: ['initMapCountry'],
            type: 'set',
        },
    },
    view: {
        toggleBookingRequest: {
            states: ['bookingRequest'],
            type: 'set',
        },
        toggleChatTab: {
            states: ['chatTab'],
            type: 'set',
        },
        togglePermaFAB: {
            states: ['permaFAB'],
            type: 'set',
        },
        toggleRecurringRequest: {
            states: ['recurringRequest'],
            type: 'set',
        },
        toggleSubscriber: {
            states: ['showSubscriber'],
            type: 'set',
        },
        toggleVerifier: {
            states: ['verifierLastShown'],
            type: 'set',
        },
    },
};
