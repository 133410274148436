import { onMounted } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { debounceTime, tap, } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { useSizeStore } from 'src/stores/size';
/**
 * Contains standard methods affecting base
 * app style
 */
const StandardStylePlugin = () => {
    /**
     * Hard disable console.log using js
     * @returns void
     */
    function disableConsole() {
        if (process.env.prodType !== 'prod')
            return;
        if (typeof window === 'undefined')
            return;
        for (const key in console) {
            const typedKey = key;
            // @ts-expect-error ignore
            console[typedKey] = function () { };
        }
    }
    const $q = useQuasar();
    const sizeStore = useSizeStore();
    /**
     * Listen to viewport height changes
     */
    function changeHeight() {
        if (typeof window !== 'undefined') {
            sizeStore.setInnerHeight(window.innerHeight);
            sizeStore.setInnerWidth(window.innerWidth);
        }
        let vh = window.innerHeight * 0.01;
        if ($q.platform.is.capacitor && $q.platform.is.ios && (window.innerWidth > window.innerHeight)) {
            vh = window.innerWidth * 0.01;
        }
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    const { subject: debouncedResize$ } = rxInitSubjectCallback();
    useObservable(debouncedResize$.pipe(debounceTime(500), tap(() => {
        changeHeight();
    })));
    // set colors and icon map
    $q.iconMapFn = (iconName) => {
        if (iconName.startsWith('font-custom-')) {
            return {
                cls: iconName,
            };
        }
        if (iconName.startsWith('get-icon-')) {
            return {
                cls: iconName,
            };
        }
    };
    onMounted(() => {
        disableConsole();
        changeHeight();
        // set standard quasar sizes
        $q.screen.setSizes({ sm: 361, md: 540, lg: 880, xl: 1280 });
        // set addrress bar color
        $q.addressbarColor.set('#008DB9');
        // dark mode
        if ($q.localStorage.getItem('dark')) {
            $q.dark.set(true);
        }
        if (typeof document !== 'undefined') {
            const body = document.body;
            body.classList.remove('opensans');
            body.classList.add('quicksand-bold');
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => {
                debouncedResize$.next('');
            }, { passive: true });
        }
    });
};
export { StandardStylePlugin };
