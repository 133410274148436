import { defineBoot } from '#q-app/wrappers';
import { v4 as uuidv4 } from 'uuid';
import date from 'quasar/src/utils/date/date.js';import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';
const { formatDate } = date;
const uniqueId = uuidv4();
const startDate = formatDate(new Date(), 'YYYYMMDD');
const sessionId = `${startDate}|${uniqueId}`;
SessionStorage.set('GA_ID', sessionId);
export default defineBoot(({ router }) => {
    if (process.env.prodType !== 'prod')
        return;
    if (process.env.SERVER)
        return;
    router.afterEach((to) => {
        window?.dataLayer.push({
            screenPath: to.path,
            screenName: to.name,
            sessionId: sessionId
        });
        window?.dataLayer.push({
            event: 'appScreenView'
        });
    });
});
