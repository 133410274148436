/**
 * Return first letter of string in uppercase
 * @param string string
 * @returns string with first letter as uppercase
 */
function capFirstWord(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Convert string with spaces to string with delimiter
 * @param string string to combine
 * @param delimiter delimiter
 * @returns string combined with delimiter
 */
function combineStringWithDelimiter(string, delimiter = '-') {
    return string.toLowerCase().replace(/\s/g, delimiter);
}
/**
 * Round number string to specified decimal places
 * @param x number
 * @param dp decimal places
 * @returns String rounded to the specified decimal places
 */
function numberWithCommas(x, dp = 2) {
    return Number(x).toFixed(dp).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
/**
 * displays the max dp of a number (i.e 1.70 will display as 1.7 while 1.75 will remain 1.75)
 * @param x string that can be converted to number
 * @param max_dp maximum number of decimal places to round to
 * @param min_dp minimum number of decimal places to display
 * @returns rounder num string
 */
function displayMaxDp(x, max_dp = 2, min_dp = 0) {
    let displayNo = x;
    while (max_dp >= 0) {
        const rounded = Number(x).toFixed(max_dp);
        if ((Number(rounded.charAt(rounded.length - 1)) !== Number(0)) ||
            (max_dp === 0) || (max_dp <= min_dp)) {
            displayNo = rounded;
            break;
        }
        max_dp -= 1;
    }
    return displayNo.toString();
}
/**
 * Split camel case string into words
 * @param str camel cased string
 * @param splitter delimiter
 * @returns split string
 */
function parseCamelCase(str, splitter = ' ') {
    if (typeof str !== 'string') {
        return '';
    }
    const splitWords = str.split(splitter);
    let completeVar = '';
    splitWords.forEach(item => {
        const capWord = item.charAt(0).toUpperCase() + item.slice(1);
        if (!completeVar) {
            completeVar = capWord;
        }
        else {
            completeVar += ` ${capWord}`;
        }
    });
    return completeVar;
}
/**
 * creates the string that we use for listing and host page url. e.g {name}-{id}
 * @param id id of listing
 * @param string name of listing
 * @returns combined string
 */
function stringParser(id = null, string = null) {
    if (id === null || id === undefined ||
        string === null || string === undefined) {
        return '';
    }
    const parsedString = string.toLowerCase().replace(/[^A-z0-9 -]/g, '');
    const routeString = `${parsedString.split(' ').join('-')}-${id}`;
    return routeString;
}
/**
 * Remove HTML from string
 * @param htmlString string for HTML removal. Replace line breaks
 * @returns string without HTML tags
 */
function htmlRemoval(htmlString) {
    if ((htmlString !== null) && (typeof htmlString !== 'undefined')) {
        return htmlString.replace(/(&nbsp;|<([^>]+)>)/ig, '').replace(/\n|\r/g, '').replace(/\\"/g, "''");
    }
    return htmlString;
}
/**
 * Remove HTML tags from string
 * @param htmlString string for HTML removal. Replace line breaks
 * @returns string without HTML tags
 */
function tagElementRemoval(htmlString) {
    if (htmlString === null || htmlString === undefined) {
        return '';
    }
    const re = new RegExp('[\\s ][A-Za-z_\\-]+\\=\\"[A-Za-z0-9;:\\-\\s ]+\\"', 'g');
    return htmlString.replace(re, '').replace(/\\"/g, "''");
}
/**
 * Check if string is url
 * @param string string to be checked for url
 * @returns boolean if string is url
 */
function isUrl(string) {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
    return (res !== null);
}
function padNumber(num, padLen) {
    const numStr = num.toString();
    if (numStr.length >= padLen) {
        return numStr;
    }
    else {
        const zerosToAdd = padLen - numStr.length;
        let paddedZeros = '';
        for (let i = 0; i < zerosToAdd; i++) {
            paddedZeros += '0';
        }
        return `${paddedZeros}${numStr}`;
    }
}
/**
 * Parse URL to get YouTube thumbnail
 * @param url url for youtube video
 * @returns image url for youtube thumbnail for display
 */
function parseYTThumbnail(url) {
    const videoUrlSplit = url.split('/');
    return `https://i.ytimg.com/vi/${videoUrlSplit[videoUrlSplit.length - 1]}/hqdefault`;
}
export { capFirstWord, combineStringWithDelimiter, displayMaxDp, numberWithCommas, isUrl, parseCamelCase, padNumber, parseYTThumbnail, stringParser, htmlRemoval, tagElementRemoval };
