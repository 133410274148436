import { actionMapper } from './actionMapper';
import { stateKeyMap } from './stateKeyMap';
import { persistedStateList } from './persistedStateList';
import cloneDeep from 'lodash.clonedeep';
import Cookies from 'quasar/src/plugins/cookies/Cookies.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';import Platform from 'quasar/src/plugins/platform/Platform.js';
import { Preferences } from '@capacitor/preferences';
/**
 * Standard method to get data from storages
 * @param param0 cookies, key to get data from and storage type
 * @returns
 */
async function getData({ cookies, forceLocalStorage, storageType, key, isTemp }) {
    if (!forceLocalStorage &&
        storageType === 'cookies') {
        return cookies.get(key);
    }
    if (storageType === 'preferences') {
        const prefValue = (await Preferences.get({ key })).value;
        if (prefValue) {
            return JSON.parse(prefValue);
        }
    }
    return isTemp
        ? SessionStorage.getItem(key)
        : LocalStorage.getItem(key);
}
/**
 * Standard method to set data to storages
 * @param param0 cookies data, local storage data, data key and data to be stored
 * @returns
 */
async function saveData({ cookies, cookieSettings, expiry, forceLocalStorage, storageType, key, data }) {
    if (typeof data === 'object' &&
        !Array.isArray(data) &&
        data !== null &&
        Object.keys(data).length === 0)
        return;
    if (!forceLocalStorage && storageType === 'cookies') {
        cookies.set(key, 
        // @ts-expect-error cookies can take in obj as well
        data, expiry
            ? { ...cookieSettings, expires: expiry }
            : cookieSettings);
        return;
    }
    if (process.env.SERVER)
        return;
    if (storageType === 'preferences') {
        await Preferences.set({ key, value: JSON.stringify(data) });
    }
    else if (expiry) {
        LocalStorage.set(key, data);
    }
    else {
        SessionStorage.set(key, data);
    }
}
/**
 * Standard methods to delete data
 * from storage
 * @param param0 cookies, key to delete
 */
async function deleteData({ cookies, cookieSettings, forceLocalStorage, key }) {
    if (!process.env.SERVER) {
        LocalStorage.remove(key);
        SessionStorage.remove(key);
        await Preferences.remove({ key });
    }
    if (!forceLocalStorage) {
        cookies.remove(key, { ...cookieSettings });
    }
}
/**
 * For each store, get the temp and perm keys from
 * the appropriate storage for the smaller data to
 * hydrate the store
 * @param storeId store id
 * @param cookies cookies
 * @param forceLocalStorage if store locally
 * @param baseCookieSettings cookie settings
 * @returns data to be patched to the store
 */
async function hydratePersistedState(storeId, cookies, forceLocalStorage, baseCookieSettings) {
    const keyAppend = ['temp', 'perm'];
    let browserStoreData = {};
    for (let i = 0; i < keyAppend.length; i++) {
        const storageDuration = keyAppend[i];
        const keyName = `${storeId}_${storageDuration}`;
        const moduleStoreData = await getData({
            cookies,
            forceLocalStorage,
            storageType: forceLocalStorage
                ? 'local'
                : 'cookies',
            key: keyName,
            isTemp: storageDuration === 'temp'
        });
        if (moduleStoreData === null)
            continue;
        // track if there are data in the storage
        // but no longer tracked
        let hasDiffering = false;
        const dataToSave = {};
        for (const moduleKey in moduleStoreData) {
            const persistedStoreKeys = persistedStateList[storeId];
            // Exclude data that is no longer tracked
            if ((forceLocalStorage && ([
                ...persistedStoreKeys.local[storageDuration],
                ...persistedStoreKeys.cookies[storageDuration]
            ].indexOf(moduleKey) === -1)) || (!forceLocalStorage && (persistedStoreKeys.cookies[storageDuration].indexOf(moduleKey) === -1))) {
                hasDiffering = true;
                continue;
            }
            // data to be updated for the current storage
            dataToSave[moduleKey] = moduleStoreData[moduleKey];
        }
        browserStoreData = {
            ...browserStoreData,
            ...dataToSave
        };
        if (!hasDiffering)
            continue;
        // if there are keys present in the current storage but
        // is no longer tracked, resave the data with the tracked
        // data only
        void saveData({
            cookies,
            cookieSettings: baseCookieSettings,
            forceLocalStorage,
            storageType: forceLocalStorage ? 'local' : 'cookies',
            expiry: storageDuration === 'temp'
                ? null
                : 30,
            key: keyName,
            data: dataToSave
        });
    }
    return {
        browserStoreData
    };
}
/**
 * Get data from data that is stored individually
 * which the data set might be large
 * @param storeId store is
 * @param forceLocalStorage if stored in local storage
 * @param cookies standard cookies
 * @returns data to be patched to the store
 */
async function hydrateLargeSets(storeId, cookies, forceLocalStorage) {
    let browserStoreData = {};
    for (const key in stateKeyMap[storeId]) {
        const stateOptions = stateKeyMap[storeId][key];
        const expiry = stateOptions.expiry;
        const stateKeyMapType = forceLocalStorage && (stateOptions.localStorageType !== undefined)
            ? stateOptions.localStorageType
            : stateOptions.type;
        if ((stateKeyMapType !== 'cookies') && (!forceLocalStorage))
            continue;
        const moduleCustomStore = await getData({
            cookies,
            forceLocalStorage,
            storageType: stateKeyMapType,
            key: stateOptions.key,
            isTemp: expiry === null
        });
        if (moduleCustomStore === null)
            continue;
        browserStoreData = {
            ...browserStoreData,
            [key]: moduleCustomStore
        };
    }
    return {
        browserStoreData
    };
}
export default async function persistPlugin({ store, pinia }, ssrContext) {
    const cookies = process.env.SERVER
        ? Cookies.parseSSR(ssrContext || {})
        : Cookies;
    const platform = process.env.SERVER
        ? Platform.parseSSR(ssrContext || {})
        : Platform;
    const forceLocalStorage = (platform.is.capacitor || false);
    const baseCookieSettings = {
        secure: !process.env.is_local,
        path: '/',
        sameSite: 'Strict'
    };
    // initialize store
    let currStateCopy = cloneDeep(store.$state);
    const currentStoreId = store.$id;
    // check if store is tracked. skip if its not
    if (!(currentStoreId in persistedStateList) &&
        !(currentStoreId in stateKeyMap))
        return;
    let toPatch = false;
    if (currentStoreId in persistedStateList) {
        const { browserStoreData } = await hydratePersistedState(currentStoreId, cookies, forceLocalStorage, baseCookieSettings);
        if (Object.keys(browserStoreData).length > 0)
            toPatch = true;
        currStateCopy = {
            ...currStateCopy,
            ...browserStoreData
        };
    }
    // get data from individual state stores
    // which might have large data sets
    if (currentStoreId in stateKeyMap) {
        const { browserStoreData } = await hydrateLargeSets(currentStoreId, cookies, forceLocalStorage);
        if (Object.keys(browserStoreData).length > 0)
            toPatch = true;
        currStateCopy = {
            ...currStateCopy,
            ...browserStoreData
        };
    }
    if (store.$id === 'central') {
        currStateCopy.piniaInitialized = true;
    }
    if (toPatch) {
        store.$patch(currStateCopy);
    }
    // limit local store data change to the state changes
    // that the action affects. If we update the store on state
    // changes (i.e $subscribe), it might cause some unwanted side effects
    store.$onAction(({ name, store, after }) => {
        // if action is not tracked
        if (!(store.$id in actionMapper))
            return;
        const action = actionMapper[store.$id];
        if (!(name in action))
            return;
        const mutatedDataSet = action[name];
        const mutatedDataStates = mutatedDataSet.states;
        const mutatedDataType = mutatedDataSet.type;
        const largePersistedStates = stateKeyMap[store.$id];
        const persistedStateSet = persistedStateList[store.$id];
        // after the action is performed
        after(async () => {
            // get state data in store
            const storeState = store.$state;
            const stateDataToSave = {
                local_temp: {},
                local_perm: {},
                cookies_temp: {},
                cookies_perm: {}
            };
            // assign state according to storage type
            for (let i = 0; i < mutatedDataStates.length; i++) {
                const stateKey = mutatedDataStates[i];
                // if key is in the large persisted state,
                // save to it immediately
                if (largePersistedStates && (stateKey in largePersistedStates)) {
                    const stateInfo = largePersistedStates[stateKey];
                    // get info for save decision
                    const key = stateInfo.key;
                    const expiry = stateInfo.expiry;
                    let stateKeyMapType = forceLocalStorage && (stateInfo.localStorageType !== undefined)
                        ? stateInfo.localStorageType
                        : stateInfo.type;
                    const data = cloneDeep(storeState[stateKey]);
                    if (mutatedDataType === 'delete' || data === null) {
                        await deleteData({
                            cookies,
                            cookieSettings: baseCookieSettings,
                            forceLocalStorage,
                            key
                        });
                        continue;
                    }
                    let customCookieExpiry = expiry;
                    // custom expiry for user cookies
                    if (stateKey === 'user' && data) {
                        if (data.remember) {
                            const userExpiry = Math.abs(new Date(data.access_token_expires).getTime() -
                                new Date().getTime()) / 1000 / 86400;
                            customCookieExpiry = Math.round(Number(userExpiry));
                        }
                        else {
                            // expires in 1 day if user is not remembered
                            customCookieExpiry = 1;
                            // dont store in persistent store
                            stateKeyMapType = 'local';
                        }
                    }
                    void saveData({
                        cookies,
                        cookieSettings: baseCookieSettings,
                        forceLocalStorage,
                        storageType: stateKeyMapType,
                        expiry: customCookieExpiry,
                        key,
                        data
                    });
                    continue;
                }
                // assign data to each store set
                for (const key in stateDataToSave) {
                    const typedKey = key;
                    const keySplit = typedKey.split('_');
                    const storedStates = persistedStateSet[keySplit[0]][keySplit[1]];
                    if (storedStates.indexOf(stateKey) === -1)
                        continue;
                    stateDataToSave[typedKey][stateKey] = storeState[stateKey];
                }
            }
            // save data in each set storage
            for (const key in stateDataToSave) {
                const typedKey = key;
                const keySplit = typedKey.split('_');
                const storageType = keySplit[0];
                const storageDuration = keySplit[1];
                const centralStore = pinia.state.value.central;
                // do not save to local storage methods before the hydration
                // plugin is called on mounted
                if (storageType === 'local' &&
                    !centralStore.localStoreInitialized)
                    continue;
                // get data from browser storage
                const currentDataFromStore = await getData({
                    cookies,
                    forceLocalStorage,
                    storageType: storageType,
                    key: `${store.$id}_${storageDuration}`,
                    isTemp: storageDuration === 'temp'
                });
                void saveData({
                    cookies,
                    cookieSettings: baseCookieSettings,
                    forceLocalStorage,
                    storageType: storageType,
                    expiry: storageDuration === 'temp'
                        ? null
                        : 30,
                    key: `${store.$id}_${storageDuration}`,
                    data: {
                        // fuse the changed state data with browser storage
                        // data to save in browser storage
                        ...(currentDataFromStore || {}),
                        ...stateDataToSave[typedKey]
                    }
                });
            }
        });
    });
}
