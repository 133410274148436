import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { useAccountStore } from '../account';
import { useAppModeStore } from '../appMode';
import { useCentralStore } from 'src/stores/central';
import { useConvoStore } from '../conversations';
import { useFlexStore } from '../flex';
import { useHostStore } from '../host';
import { usePropertyStore } from '../property';
import { useSearchStore } from '../search';
import { useViewStore } from '../view';
import { stateKeyMap } from './stateKeyMap';
import { persistedStateList } from './persistedStateList';
/**
 * hydrate store using data from local storages
 * after app is mounted and have access to local
 * type of storages
 */
const SsrStoreHydrationPlugin = () => {
    const $q = useQuasar();
    const vueRoute = useRoute();
    const accountStore = useAccountStore();
    const appModeStore = useAppModeStore();
    const centralStore = useCentralStore();
    const convoStore = useConvoStore();
    const flexStore = useFlexStore();
    const hostStore = useHostStore();
    const propertyStore = usePropertyStore();
    const searchStore = useSearchStore();
    const viewStore = useViewStore();
    const localStoreInitialized = computed({
        get: () => centralStore.localStoreInitialized,
        set: (newValue) => {
            centralStore.toggleLocalStoreInitialized(newValue);
        }
    });
    /**
     * for migration of data from cookies to local storages
     * to delete in June 2023
     *  */
    function cookieStoreMigration(typedKey, keyName, persistenceKey) {
        const cookieStoreData = $q.cookies.get(keyName);
        if (!cookieStoreData)
            return;
        /**
         * Split data to be stored locally or in cookies.
         * Get data from localstorages to append to the data
         * if it exists
         */
        const splitData = {
            'cookies': {},
            'local': persistenceKey === 'temp'
                ? $q.sessionStorage.getItem(keyName) || {}
                : $q.localStorage.getItem(keyName) || {}
        };
        const listOfDataToStoreInCookies = persistedStateList[typedKey].cookies[persistenceKey];
        const listOfDataToStoreInLocal = persistedStateList[typedKey].local[persistenceKey];
        for (const cookieStoreKey in cookieStoreData) {
            const typedCookieStoreKey = cookieStoreKey;
            const cookieData = cookieStoreData[typedCookieStoreKey];
            if (listOfDataToStoreInCookies.indexOf(typedCookieStoreKey.toString()) > -1) {
                splitData.cookies[typedCookieStoreKey] = cookieData;
            }
            if (listOfDataToStoreInLocal.indexOf(typedCookieStoreKey.toString()) > -1) {
                splitData.local[typedCookieStoreKey] = cookieData;
            }
        }
        // save split data to cookies if object is not empty
        if (Object.keys(splitData.cookies).length > 0) {
            const cookieSettings = {
                secure: !process.env.is_local,
                path: '/',
                sameSite: 'Strict'
            };
            if (persistenceKey === 'perm') {
                cookieSettings.expires = 30;
            }
            $q.cookies.set(keyName, 
            // @ts-expect-error cookies can take in obj as well
            splitData.cookies, cookieSettings);
        }
        else {
            // remove cookies if data is empty
            $q.cookies.remove(keyName);
        }
        // save split data to local storage if object is not empty
        if (Object.keys(splitData.local).length === 0) {
            if (persistenceKey === 'temp') {
                $q.sessionStorage.set(keyName, splitData.local);
            }
            else {
                $q.localStorage.set(keyName, splitData.local);
            }
        }
    }
    function initializeLocalStore() {
        const statesData = {};
        /**
         * Get the grouped data
         */
        for (const key in persistedStateList) {
            const typedKey = key;
            const keyAppend = ['temp', 'perm'];
            for (let i = 0; i < keyAppend.length; i++) {
                const persistenceKey = keyAppend[i];
                const keyName = `${typedKey}_${persistenceKey}`;
                cookieStoreMigration(typedKey, keyName, persistenceKey);
                /**
                 * Get data from local storage
                 */
                const moduleStoreData = persistenceKey === 'temp'
                    ? $q.sessionStorage.getItem(keyName)
                    : $q.localStorage.getItem(keyName);
                if (moduleStoreData === null)
                    continue;
                if (!(typedKey in statesData)) {
                    statesData[typedKey] = {};
                }
                const cleanedStoreData = {};
                let hasDiffering = false;
                for (const storeDataKey in moduleStoreData) {
                    if (
                    // data thats no longer tracked
                    persistedStateList[typedKey].local[persistenceKey].indexOf(storeDataKey) === -1) {
                        hasDiffering = true;
                        continue;
                    }
                    // data for updating the current storage and
                    // hydrate the store
                    cleanedStoreData[storeDataKey] = moduleStoreData[storeDataKey];
                }
                if (hasDiffering) {
                    if (persistenceKey === 'temp') {
                        $q.sessionStorage.set(keyName, cleanedStoreData);
                    }
                    else {
                        $q.localStorage.set(keyName, cleanedStoreData);
                    }
                }
                statesData[typedKey] = {
                    ...statesData[typedKey],
                    ...cleanedStoreData
                };
            }
        }
        /**
         * Get data with custom keys from storage
         */
        for (const storeKey in stateKeyMap) {
            const typedStoreKey = storeKey;
            for (const stateKey in stateKeyMap[typedStoreKey]) {
                const stateData = stateKeyMap[typedStoreKey][stateKey];
                const storeStateMapType = stateData.type;
                const expiry = stateData.expiry;
                const storageKey = stateData.key;
                const skipHydrationRoutes = stateData.skipHydrationRouteNames;
                // if map type is cookies, continue as its
                // already taken care in the plugin on create
                if (storeStateMapType !== 'local')
                    continue;
                // skip hydrate on specific routes
                if (skipHydrationRoutes.indexOf((vueRoute.name || '').toString()) > -1)
                    continue;
                // get data
                const storeData = expiry === null
                    ? $q.sessionStorage.getItem(storageKey)
                    : $q.localStorage.getItem(storageKey);
                // skip if data is not found
                if (storeData === null)
                    continue;
                if ((storageKey === 'userMisc') && !storeData.remember) {
                    // check if user cookie is still there if it should be forgotten
                    // after 1 day. if cookie is expired, remove the data and stop hydrating
                    // the store for userMisc
                    const userCookie = $q.cookies.get('user');
                    if (!userCookie) {
                        $q.localStorage.remove(storageKey);
                        continue;
                    }
                }
                if (!(typedStoreKey in statesData)) {
                    statesData[typedStoreKey] = {};
                }
                // set data
                statesData[typedStoreKey] = {
                    ...statesData[typedStoreKey],
                    [stateKey]: storeData
                };
            }
        }
        /**
         * Hydrate store
         */
        for (const moduleKey in statesData) {
            const moduleData = statesData[moduleKey];
            switch (moduleKey) {
                case 'account':
                    accountStore.$patch(moduleData);
                    break;
                case 'app':
                    appModeStore.$patch(moduleData);
                    break;
                case 'central':
                    centralStore.$patch(moduleData);
                    break;
                case 'conversations':
                    convoStore.$patch(moduleData);
                    break;
                case 'flex':
                    flexStore.$patch(moduleData);
                    break;
                case 'host':
                    hostStore.$patch(moduleData);
                    break;
                case 'property':
                    propertyStore.$patch(moduleData);
                    break;
                case 'searches':
                    searchStore.$patch(moduleData);
                    break;
                case 'view':
                    viewStore.$patch(moduleData);
                    break;
            }
        }
    }
    onMounted(() => {
        // only do this in ssr mode as the store has already been hydrated
        // for non ssr in the plugin
        if (!localStoreInitialized.value &&
            ['ssr', 'pwa', 'spa'].indexOf(process.env.MODE || '') > -1) {
            initializeLocalStore();
        }
        localStoreInitialized.value = true;
    });
};
export { SsrStoreHydrationPlugin };
