/**
 * Return full url from string
 * @param url url with or without format at the end
 * @param format format of the image
 * @returns full url
 */
function checkAndReturnUrl(url, format = null) {
    const formatExt = format === ''
        ? ''
        : format
            ? `.${format}`
            : '.jpeg';
    return (!(/\.[a-zA-Z0-9]+$/.test(url)) &&
        !(/^https\\:\/\/firebasestorage.googleapis.com/.test(url)) &&
        !(/^https\\:\/\/lh3.googleusercontent.com/.test(url)))
        ? `${url}${formatExt}`
        : url;
}
/**
 * Return full url for image
 * @param urlStrOrObj url string or object
 * @param format format of the image
 * @returns full url of image
 */
function checkUrlObjAndReturnUrl(urlStrOrObj = null, format = null) {
    // Check if the url passed in is string or object
    // assume object passed in is always standarized to 
    // consist of url and format key
    if (typeof urlStrOrObj === 'object' &&
        !Array.isArray(urlStrOrObj) &&
        urlStrOrObj !== null) {
        return checkAndReturnUrl(urlStrOrObj.url, urlStrOrObj.format);
    }
    return urlStrOrObj
        ? checkAndReturnUrl(urlStrOrObj, format)
        : '';
}
/**
 * Convert url string to webp
 * @param url url string
 * @returns url string converted to .webp
 */
function convertUrlToWebp(url) {
    if (/\.[a-zA-Z0-9]+$/.test(url)) {
        return url.replace(/\.[a-zA-Z0-9]+$/, '.webp');
    }
    return url ? `${url}.webp` : url;
}
/**
 * Get profile image based on first char of name
 * @param name name of profile page
 * @returns
 */
function profileImageDefault(name) {
    // get ascii pos of the first char in the profile name
    const firstChar = (name.charCodeAt(0)) - 64;
    return `https://dminqct125wnz.cloudfront.net/marketing/profile/profile_profile_${(firstChar % 9).toFixed(0)}.jpeg`;
}
export { checkAndReturnUrl, checkUrlObjAndReturnUrl, convertUrlToWebp, profileImageDefault };
