import { defineBoot } from '#q-app/wrappers';
import axios from 'axios';
export default defineBoot(({ ssrContext }) => {
    if (ssrContext) {
        const exjsReq = ssrContext.req;
        const forwarded = exjsReq.headers['x-forwarded-for'];
        let ip = exjsReq.socket.remoteAddress;
        if (forwarded && Array.isArray(forwarded)) {
            ip = forwarded[0];
        }
        else if (forwarded) {
            ip = forwarded.split(/, /)[0];
        }
        axios.defaults.headers.common['X-Real-IP'] = ip;
        axios.defaults.headers.common['X-Client-IP'] = ip;
        axios.defaults.headers.common['X-CUSTOM-REFERER'] = `https://${process.env.domain}`;
    }
});
