import { convertUrlToWebp } from 'src/plugins/images/general';
const imageSizes = {
    space_images: {
        thumbnail: 72,
        xs: 512,
        sm: 768,
        md: 1024,
        xmd: 1280,
        lg: 1500
    },
    old_landing_page: {
        xxl: 1500,
        xl: 1200,
        lg: 880,
        md: 540,
        sm: 360
    },
    space_ad_images: {
        thumbnail: 72,
        xs: 512,
        sm: 768,
        md: 1024,
        xmd: 1280,
        lg: 1500
    },
    landing_page: {
        360: 360,
        640: 640,
        768: 768,
        1024: 1024,
        1280: 1280,
        1366: 1366,
        1600: 1600,
        1920: 1920
    },
    new_listing: {
        '128': 128,
        '256': 256,
        '384': 384,
        '512': 512,
        '640': 640,
        '896': 896,
        '1048': 1048,
        '1280': 1280,
        '1408': 1408,
        '1600': 1600,
        '1920': 1920
    },
    profile: {
        large: 360,
        mid: 256,
        profile: 180,
        sm: 96,
        xs: 48
    }
};
/**
 * generate image src strings (webp, normal and the base href url)
 * @param param0 {
 *    url: url of the image
 *    originalSize: size string label of the image
 *    sizeType: size type of the image map
 *    defaultSize: size that are used the most
 * }
 * @returns
 */
function generateSrcString({ url, originalSize, sizeType, defaultSize = null }) {
    const test = (new RegExp('^(https://image-mgt.sgp1.cdn.digitaloceanspaces.com|https://dminqct125wnz.cloudfront.net|https://s3.ap-southeast-1.amazonaws.com)', 'g')).test(url);
    let replacedUrl = url;
    if (defaultSize) {
        const sizeRegex = new RegExp(`${originalSize}`, 'g');
        replacedUrl = url.replace(sizeRegex, defaultSize);
    }
    if (test && ([
        'space_images',
        'old_landing_page',
        'landing_page',
        'new_listing',
        'profile'
    ].indexOf(sizeType || '') > -1)) {
        let originalSet = '';
        let webpSet = '';
        const regex = new RegExp(`(${originalSize})\\w+\\.\\w+$`, 'g');
        const fileNameMatch = url.match(/_\w+\.\w+$/g);
        if (fileNameMatch) {
            const fileName = fileNameMatch[0];
            const sizesObj = imageSizes[sizeType];
            for (const size in sizesObj) {
                const width = sizesObj[size];
                const srcSetNew = url.replace(regex, `${size}${fileName}`);
                const webpSrc = convertUrlToWebp(srcSetNew);
                originalSet += `${srcSetNew} ${width}w, `;
                webpSet += `${webpSrc} ${width}w, `;
            }
        }
        return {
            original: originalSet,
            webp: webpSet,
            url: replacedUrl
        };
    }
    else {
        return {
            url: replacedUrl,
            original: url,
            webp: ''
        };
    }
}
export { imageSizes, generateSrcString };
